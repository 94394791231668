import environment from "web/script/modules/environment";
import { isActiveBanner } from "web/script/utils/active-campaigns";

export interface BannerCampaign {
    name: string;
    flag: string;
    bannerImageUrl: string;
    bannerSize: BannerSize;
}

export interface BranchBannerCampaign extends BannerCampaign {
    branchLink: string;
}

export enum BannerSize {
    None = "none",
    Small = "small",
}

const DEFAULT_BANNER_IMAGE =
    "https://static.lystit.com/static/n/static-img/app-download-banner/app_screen_162x300.png";
const DEFAULT_BANNER_SIZE = BannerSize.Small;

export function getBannerCampaign(
    initialLandingPage: string,
    initialSubType: string
): null | BannerCampaign {
    if (environment.get("pageType") === "checkout") {
        return null;
    }

    let campaignName = "";
    let flag = "";
    let bannerImageUrl = DEFAULT_BANNER_IMAGE;
    let bannerSize = DEFAULT_BANNER_SIZE;

    if (isActiveBanner("mg_app_banner_feed") && initialLandingPage === "feed") {
        flag = "feed";
        campaignName = "Web to App - Feed - General";
    } else if (isActiveBanner("mg_app_banner_homepage") && initialLandingPage === "homepage") {
        flag = "homepage";
        campaignName = "Web to App - Homepage - General";
    } else if (
        isActiveBanner("mg_app_banner_is_pdp") &&
        initialLandingPage === "product" &&
        initialSubType === "in_stock"
    ) {
        flag = "is_pdp";
        campaignName = "Web to App - In Stock PDP - General";
    } else if (
        isActiveBanner("mg_app_banner_oos_pdp") &&
        initialLandingPage === "product" &&
        initialSubType === "out_of_stock"
    ) {
        flag = "oos_pdp";
        campaignName = "Web to App - Out of Stock PDP - General";
    } else if (
        isActiveBanner("mg_app_banner_css_lead_return") &&
        initialLandingPage === "lead" &&
        environment.get("pageType") !== "lead"
    ) {
        flag = "lead_return";
        campaignName = "Web to App - CSS Lead Return - General";
    } else {
        return null;
    }

    return {
        name: campaignName,
        flag: flag,
        bannerImageUrl: bannerImageUrl,
        bannerSize: bannerSize,
    };
}
