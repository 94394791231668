import cloneDeep from "lodash/cloneDeep";
import every from "lodash/every";
import isEqual from "lodash/isEqual";
import noop from "lodash/noop";
import { useEffect, useState } from "react";
import { POSTCODE_REGEX } from "web/react/components/input-components/address-input/address-input-components/address-postcode-input";
import { ZIPCODE_REGEX } from "web/react/components/input-components/address-input/address-input-components/address-zip-code-input";
import { EMAIL_REGEX } from "web/react/components/input-components/email-input/email-input";
import analytics from "web/script/analytics/analytics";
import { gettext } from "web/script/modules/django-i18n";
import environment from "web/script/modules/environment";
import { Address } from "web/types/address";
import { BillingShippingInformation } from "web/types/customer-information";
import { FieldData } from "web/types/form";
import { Name } from "web/types/name";
import {
    CheckoutBillingShippingInformationSerializer,
    CheckoutLayoutSerializer,
} from "web/types/serializers";

const country = environment.get("country");

interface UseShippingBillingFormOptions {
    initialData: CheckoutLayoutSerializer;
}

interface BaseShippingBillingFormDetails {
    billingAddressQueryText: string;
    billingAddressSearchError: string;
    billingInformation: BillingShippingInformation;
    customerEmail: FieldData;
    isShippingFormComplete: boolean;
    hasShippingFormErrored: boolean;
    onBillingInformationChange: (billingInformation: BillingShippingInformation) => void;
    onCustomerEmailChange: (email: FieldData) => void;
    onShippingInformationChange: (shippingInformation: BillingShippingInformation) => void;
    setBillingAddressQueryText: (queryText: string) => void;
    setBillingAddressSearchError: (error: string) => void;
    setShippingAddressQueryText: (queryText: string) => void;
    setShowBillingAddressSearch: (show: boolean) => void;
    setShowShippingAddressSearch: (show: boolean) => void;
    shippingAddressQueryText: string;
    shippingInformation: BillingShippingInformation;
    showBillingAddressSearch: boolean;
    showShippingAddressSearch: boolean;
    showShippingLoadingSpinner: boolean;
    sendShippingFormChangedAnalytics: () => void;
    setShippingAddressSearchError: (error: string) => void;
    shippingAddressSearchError: string;
}

interface UseShippingBillingForm extends BaseShippingBillingFormDetails {
    setShippingFormComplete: (complete: boolean) => void;
    setShippingFormErrored: (errored: boolean) => void;
    setShippingLoadingSpinner: (loading: boolean) => void;
    validateShippingBillingInformation: () => boolean;
}

export interface CheckoutContextShippingForm extends BaseShippingBillingFormDetails {
    isShippingFormDisabled: boolean;
    onSubmitShippingForm: () => void;
    validateShippingBillingInformation: () => boolean;
}

const blankFormData = { error: "", value: "" };
export const CHECKOUT_CONTEXT_SHIPPING_FORM_DEFAULT_DATA: CheckoutContextShippingForm = {
    billingAddressQueryText: "",
    billingAddressSearchError: "",
    billingInformation: {
        address: {
            addressLine1: blankFormData,
            addressLine2: blankFormData,
            city: blankFormData,
            countryCode: { error: "", value: environment.get("country") },
            region: blankFormData,
            zipCode: blankFormData,
        },
        name: {
            firstName: blankFormData,
            lastName: blankFormData,
        },
        phoneNumber: blankFormData,
    },
    isShippingFormComplete: true,
    isShippingFormDisabled: false,
    hasShippingFormErrored: false,
    onBillingInformationChange: noop,
    onShippingInformationChange: noop,
    onSubmitShippingForm: noop,
    setBillingAddressQueryText: noop,
    setBillingAddressSearchError: noop,
    setShippingAddressQueryText: noop,
    setShowBillingAddressSearch: noop,
    setShowShippingAddressSearch: noop,
    shippingInformation: {
        address: {
            addressLine1: blankFormData,
            addressLine2: blankFormData,
            city: blankFormData,
            countryCode: { error: "", value: environment.get("country") },
            region: blankFormData,
            zipCode: blankFormData,
        },
        name: {
            firstName: blankFormData,
            lastName: blankFormData,
        },
        phoneNumber: blankFormData,
    },
    showShippingLoadingSpinner: false,
    customerEmail: blankFormData,
    onCustomerEmailChange: noop,
    shippingAddressQueryText: "",
    showBillingAddressSearch: true,
    showShippingAddressSearch: true,
    sendShippingFormChangedAnalytics: noop,
    validateShippingBillingInformation: () => true,
    setShippingAddressSearchError: noop,
    shippingAddressSearchError: "",
};

function createFormField(value: string | undefined = ""): FieldData {
    return {
        error: "",
        value: value,
    };
}

export function convertBillingShippingInformation(
    serializedBillingInformation: CheckoutBillingShippingInformationSerializer
): BillingShippingInformation {
    return {
        address: {
            addressLine1: createFormField(serializedBillingInformation.address.address_line_1),
            addressLine2: createFormField(serializedBillingInformation.address.address_line_2),
            city: createFormField(serializedBillingInformation.address.city),
            countryCode: createFormField(
                serializedBillingInformation.address.country_code || country
            ),
            region: createFormField(serializedBillingInformation.address.region_code),
            zipCode: createFormField(serializedBillingInformation.address.zip_code),
        },
        name: {
            firstName: createFormField(serializedBillingInformation.first_name),
            lastName: createFormField(serializedBillingInformation.last_name),
        },
        phoneNumber: createFormField(serializedBillingInformation.phone_number),
    };
}

export function convertBillingShippingInformationToSerializer(
    billingInformation: BillingShippingInformation
): CheckoutBillingShippingInformationSerializer {
    return {
        address: {
            address_line_1: billingInformation.address.addressLine1.value,
            address_line_2: billingInformation.address.addressLine2.value,
            city: billingInformation.address.city.value,
            country_code: billingInformation.address.countryCode.value,
            region_code: billingInformation.address.region.value,
            zip_code: billingInformation.address.zipCode.value,
        },
        first_name: billingInformation.name.firstName.value,
        last_name: billingInformation.name.lastName.value,
        phone_number: billingInformation.phoneNumber.value,
    };
}

export function getValidationErrorMessage(
    validationError: keyof ValidityState,
    displayName: string
): string {
    const VALIDATION_ERROR_MESSAGE_TOKENS: { [Property in keyof ValidityState]?: string } = {
        patternMismatch: gettext("input.validation.invalid", { field_name: displayName }),
        typeMismatch: gettext("input.validation.invalid", { field_name: displayName }),
        valueMissing: gettext("input.validation.value_missing", { field_name: displayName }),
    };

    return VALIDATION_ERROR_MESSAGE_TOKENS[validationError] || "";
}

function useShippingBillingForm({
    initialData,
}: UseShippingBillingFormOptions): UseShippingBillingForm {
    const [isShippingFormComplete, setShippingFormComplete] = useState<boolean>(
        initialData.is_shipping_complete
    );
    const [showShippingLoadingSpinner, setShippingLoadingSpinner] = useState<boolean>(false);
    const [hasShippingFormErrored, setShippingFormErrored] = useState<boolean>(false);
    const [billingInformation, setBillingInformation] = useState<BillingShippingInformation>(
        convertBillingShippingInformation(initialData.billing_information)
    );
    const [shippingInformation, setShippingInformation] = useState<BillingShippingInformation>(
        convertBillingShippingInformation(initialData.shipping_information)
    );
    const [customerEmail, setCustomerEmail] = useState<FieldData>({
        value: initialData.email || "",
        error: "",
    });

    const [showBillingAddressSearch, setShowBillingAddressSearch] = useState<boolean>(
        !initialData.billing_information.address.address_line_1
    );
    const [showShippingAddressSearch, setShowShippingAddressSearch] = useState<boolean>(
        !initialData.shipping_information.address.address_line_1
    );
    const [billingAddressQueryText, setBillingAddressQueryText] = useState<string>("");
    const [shippingAddressQueryText, setShippingAddressQueryText] = useState<string>("");
    const [isShippingAnalyticSent, setIsShippingAnalyticSent] = useState(false);
    const [shippingAddressSearchError, setShippingAddressSearchError] = useState("");
    const [billingAddressSearchError, setBillingAddressSearchError] = useState("");

    useEffect(() => {
        setShippingAddressSearchError("");
    }, [showShippingAddressSearch]);

    useEffect(() => {
        setBillingAddressSearchError("");
    }, [showBillingAddressSearch]);

    function onBillingInformationChange(billingInformation: BillingShippingInformation): void {
        setBillingInformation(billingInformation);
        trackShippingFormChanged();
    }

    function onShippingInformationChange(shippingInformation: BillingShippingInformation): void {
        setShippingInformation(shippingInformation);
        trackShippingFormChanged();
    }

    function onCustomerEmailChange(email: FieldData): void {
        setCustomerEmail(email);
        trackShippingFormChanged();
    }

    function trackShippingFormChanged(): void {
        const hasBillingChanged = !isEqual(
            billingInformation,
            convertBillingShippingInformation(initialData.billing_information)
        );
        const hasShippingChanged = !isEqual(
            shippingInformation,
            convertBillingShippingInformation(initialData.shipping_information)
        );
        const hasEmailChanged = customerEmail.value !== initialData.email;

        if (hasBillingChanged || hasShippingChanged || hasEmailChanged) {
            sendShippingFormChangedAnalytics();
        }
    }

    function sendShippingFormChangedAnalytics(): void {
        // Only to be fired once per shipping form screen view
        if (!isShippingAnalyticSent) {
            analytics.event("shipping_form", "changed");
            setIsShippingAnalyticSent(true);
        }
    }

    function validateShippingBillingInformation(): boolean {
        const customerEmailValidation = validateCustomerEmail(customerEmail.value);
        setCustomerEmail(customerEmailValidation.emailAddress);

        const shippingAddressValidation = validateShippingAddress();
        const shippingNameValidation = validateShippingName();
        const shippingPhoneNumberValidation = validatePhoneNumber(
            shippingInformation.phoneNumber.value
        );

        const newShippingInformation = cloneDeep(shippingInformation);
        newShippingInformation.address = shippingAddressValidation.address;
        newShippingInformation.name = shippingNameValidation.name;
        newShippingInformation.phoneNumber = shippingPhoneNumberValidation.phoneNumber;
        setShippingInformation(newShippingInformation);

        if (!shippingAddressValidation.isValid && showShippingAddressSearch) {
            setShippingAddressSearchError("Address is required");
        }

        const billingAddressValidation = validateBillingAddress();
        const billingNameValidation = validateBillingName();
        const billingPhoneNumberValidation = validatePhoneNumber(
            billingInformation.phoneNumber.value
        );

        const newBillingInformation = cloneDeep(billingInformation);
        newBillingInformation.address = billingAddressValidation.address;
        newBillingInformation.name = billingNameValidation.name;
        newBillingInformation.phoneNumber = billingPhoneNumberValidation.phoneNumber;
        setBillingInformation(newBillingInformation);

        if (!shippingAddressValidation.isValid && showShippingAddressSearch) {
            setBillingAddressSearchError("Address is required");
        }

        const isValid = every(
            [
                customerEmailValidation,
                shippingAddressValidation,
                shippingNameValidation,
                shippingPhoneNumberValidation,
                billingAddressValidation,
                billingNameValidation,
                billingPhoneNumberValidation,
            ],
            (element) => element.isValid
        );

        return isValid;
    }

    function validatePhoneNumber(phoneNumber: string): {
        phoneNumber: FieldData;
        isValid: boolean;
    } {
        let error = "";

        if (!phoneNumber) {
            error = getValidationErrorMessage(
                "valueMissing",
                gettext("input.phone_number_input.field_name")
            );
        }

        return {
            phoneNumber: {
                value: phoneNumber,
                error,
            },
            isValid: !error,
        };
    }

    function validateCustomerEmail(emailAddress: string): {
        emailAddress: FieldData;
        isValid: boolean;
    } {
        let error = "";

        if (!emailAddress) {
            error = getValidationErrorMessage(
                "valueMissing",
                gettext("input.email_input.field_name")
            );
        } else if (!new RegExp(EMAIL_REGEX).test(emailAddress)) {
            error = getValidationErrorMessage(
                "patternMismatch",
                gettext("input.email_input.field_name")
            );
        }

        return {
            emailAddress: {
                value: emailAddress,
                error,
            },
            isValid: !error,
        };
    }

    function validateBillingName(): {
        name: Name;
        isValid: boolean;
    } {
        const firstNameValidation = validateFirstName(billingInformation.name.firstName.value);
        const lastNameValidation = validateLastName(billingInformation.name.lastName.value);

        const newName = cloneDeep(billingInformation.name);
        newName.firstName.error = firstNameValidation.error;
        newName.lastName.error = lastNameValidation.error;

        return {
            name: newName,
            isValid: firstNameValidation.isValid && lastNameValidation.isValid,
        };
    }

    function validateShippingName(): {
        name: Name;
        isValid: boolean;
    } {
        const firstNameValidation = validateFirstName(shippingInformation.name.firstName.value);
        const lastNameValidation = validateLastName(shippingInformation.name.lastName.value);

        const newName = cloneDeep(shippingInformation.name);
        newName.firstName.error = firstNameValidation.error;
        newName.lastName.error = lastNameValidation.error;

        return {
            name: newName,
            isValid: firstNameValidation.isValid && lastNameValidation.isValid,
        };
    }

    function validateFirstName(firstName: string): {
        error: string;
        isValid: boolean;
    } {
        let error = "";

        if (!firstName) {
            error = getValidationErrorMessage(
                "valueMissing",
                gettext("input.name_input.first_name.field_name")
            );
        }

        return {
            error,
            isValid: !error,
        };
    }

    function validateLastName(lastName: string): {
        error: string;
        isValid: boolean;
    } {
        let error = "";

        if (!lastName) {
            error = getValidationErrorMessage(
                "valueMissing",
                gettext("input.name_input.last_name.field_name")
            );
        }

        return {
            error,
            isValid: !error,
        };
    }

    function validateBillingAddress(): {
        address: Address;
        isValid: boolean;
    } {
        return validateAddress(billingInformation.address);
    }

    function validateShippingAddress(): {
        address: Address;
        isValid: boolean;
    } {
        return validateAddress(shippingInformation.address);
    }

    function validateAddress(address: Address): {
        address: Address;
        isValid: boolean;
    } {
        let newAddress: {
            address: Address;
            isValid: boolean;
        };

        switch (address.countryCode.value) {
            case "US":
                newAddress = validateAddressUS(address);
                break;
            default:
                newAddress = validateAddressGB(address);
                break;
        }

        return newAddress;
    }

    function validateAddressGB(address: Address): {
        address: Address;
        isValid: boolean;
    } {
        const addressLine1 = validateAddressLine1(address.addressLine1.value);
        const city = validateCity(address.city.value);
        const postCode = validateGBPostCode(address.zipCode.value);

        const newAddress = cloneDeep(address);
        newAddress.addressLine1.error = addressLine1.error;
        newAddress.city.error = city.error;
        newAddress.zipCode.error = postCode.error;

        return {
            address: newAddress,
            isValid: addressLine1.isValid && city.isValid && postCode.isValid,
        };
    }

    function validateAddressUS(address: Address): {
        address: Address;
        isValid: boolean;
    } {
        const addressLine1 = validateAddressLine1(address.addressLine1.value);
        const city = validateCity(address.city.value);
        const state = validateUSState(address.region.value);
        const zipCode = validateUSZipCode(address.zipCode.value);

        const newAddress = cloneDeep(address);
        newAddress.addressLine1.error = addressLine1.error;
        newAddress.city.error = city.error;
        newAddress.region.error = state.error;
        newAddress.zipCode.error = zipCode.error;

        return {
            address: newAddress,
            isValid: addressLine1.isValid && city.isValid && state.isValid && zipCode.isValid,
        };
    }

    function validateAddressLine1(addressLine1: string): {
        error: string;
        isValid: boolean;
    } {
        let error = "";

        if (!addressLine1) {
            error = getValidationErrorMessage(
                "valueMissing",
                gettext("input.address_input.address_line_1.field_name")
            );
        }

        return {
            error,
            isValid: !error,
        };
    }

    function validateCity(city: string): {
        error: string;
        isValid: boolean;
    } {
        let error = "";

        if (!city) {
            error = getValidationErrorMessage(
                "valueMissing",
                gettext("input.address_input.city.field_name")
            );
        }

        return {
            error,
            isValid: !error,
        };
    }

    function validateUSState(state: string): {
        error: string;
        isValid: boolean;
    } {
        let error = "";

        if (!state) {
            error = getValidationErrorMessage(
                "valueMissing",
                gettext("input.address_input.state.field_name")
            );
        }

        return {
            error,
            isValid: !error,
        };
    }

    function validateUSZipCode(zipCode: string): {
        error: string;
        isValid: boolean;
    } {
        const zipCodeRegex = new RegExp(ZIPCODE_REGEX);
        const zipCodeFieldName = gettext("input.address_input.zip_code.field_name");

        let error = "";

        if (!zipCode) {
            error = getValidationErrorMessage("valueMissing", zipCodeFieldName);
        } else if (!zipCodeRegex.test(zipCode)) {
            error = getValidationErrorMessage("patternMismatch", zipCodeFieldName);
        }

        return {
            error,
            isValid: !error,
        };
    }

    function validateGBPostCode(postCode: string): {
        error: string;
        isValid: boolean;
    } {
        const postCodeRegex = new RegExp(POSTCODE_REGEX);
        const postCodeFieldName = gettext("input.address_input.postcode.field_name");

        let error = "";

        if (!postCode) {
            error = getValidationErrorMessage("valueMissing", postCodeFieldName);
        } else if (!postCodeRegex.test(postCode)) {
            error = getValidationErrorMessage("patternMismatch", postCodeFieldName);
        }

        return {
            error,
            isValid: !error,
        };
    }

    return {
        billingAddressQueryText,
        billingAddressSearchError,
        billingInformation,
        customerEmail,
        isShippingFormComplete,
        hasShippingFormErrored,
        onBillingInformationChange,
        onCustomerEmailChange,
        onShippingInformationChange,
        setBillingAddressQueryText,
        setBillingAddressSearchError,
        setShippingAddressQueryText,
        setShippingAddressSearchError,
        setShippingFormComplete,
        setShippingFormErrored,
        setShippingLoadingSpinner,
        setShowBillingAddressSearch,
        setShowShippingAddressSearch,
        shippingAddressQueryText,
        shippingAddressSearchError,
        shippingInformation,
        showBillingAddressSearch,
        showShippingAddressSearch,
        showShippingLoadingSpinner,
        sendShippingFormChangedAnalytics,
        validateShippingBillingInformation,
    };
}

export default useShippingBillingForm;
