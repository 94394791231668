import clsx from "clsx";
import React, { useRef, useState } from "react";
import { useNavigationContext } from "web/react/components/navigation/navigation.context";
import SVGIcon from "web/react/components/svg-icon/svg-icon";
import { Button } from "web/react/emo/button";
import { Flyout } from "web/react/emo/flyout/flyout";
import { ListItem } from "web/react/emo/list-item";
import { Text } from "web/react/emo/text";
import { View } from "web/react/emo/view";
import analytics from "web/script/analytics/analytics";
import { gettext } from "web/script/modules/django-i18n";
import requester from "web/script/modules/requester";
import { AppPreHeaderBarCountryPickerSerializer } from "web/types/serializers";
import * as styles from "./country-selector.css";

type Country = string[];
export interface CountrySelectorProps {
    countryInfo: AppPreHeaderBarCountryPickerSerializer;
    showCountryPicker: boolean;
}

export function CountrySelector({
    countryInfo,
    showCountryPicker,
}: CountrySelectorProps): React.ReactElement {
    const { showMenu, showSearch, handleHeaderAnalytics } = useNavigationContext();

    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [selectedCountry, setSelectedCountry] = useState<Country | null>(null);
    const [isSelectorOpen, setIsSelectorOpen] = useState<boolean>(false);
    const [allCountries, setAllCountries] = useState([]);

    const flyoutRef = useRef<HTMLDivElement>(null);

    async function onOpen(): Promise<void> {
        // If the picker is opened just close it
        if (isOpen) {
            setIsOpen(false);
            return;
        }

        let currentCountry;

        // Fill the countries list from the backend
        // but we want to fill it once and not make this request anymore
        // so the list will be kept in state
        if (!allCountries.length) {
            const data = await requester.get("/account/get_countries/");
            setAllCountries(data.all_countries);
            currentCountry =
                data.all_countries.find((country) => country[0] === countryInfo.country) || null;
        } else {
            currentCountry =
                allCountries.find((country) => country[0] === countryInfo.country) || null;
        }

        setSelectedCountry(currentCountry);
        handleHeaderAnalytics("country_selector", "opened");

        setIsOpen(true);
    }

    function onCountrySubmit(country: Country): void {
        const [countryCode, newCountry] = country;

        analytics.event("SETTINGS", "CHANGE_COUNTRY", countryInfo.country + "-to-" + newCountry);
        requester("/account/set_country/", {
            method: "POST",
            body: { country: countryCode } as any,
            headers: {
                Accept: "text/html",
            },
        }).then(function () {
            window.location.reload();
        });
    }

    return (
        <div data-testid="country-selector" ref={flyoutRef} className={styles.wrapper}>
            <button
                aria-label={gettext("header.country_picker.label")} // country picker
                onClick={onOpen}
                type="button"
                className={styles.countrySelector}
                disabled={!showCountryPicker}
            >
                <Text textStyle="body-3">
                    <div className={styles.countryContainer}>
                        {countryInfo.country}
                        <span className={styles.dot} />
                        {countryInfo.currency_indicator}
                    </div>
                </Text>
            </button>

            {isOpen && !showMenu && !showSearch && (
                <div className={styles.flyOutContent}>
                    <Flyout
                        isOpen={isOpen}
                        parentRef={flyoutRef}
                        handleClose={() => {
                            setIsOpen(false);
                            setSelectedCountry(null);
                        }}
                    >
                        {/* TODO: this dropdown can be separate out into a new /emo/ design system component */}
                        {/* https://jira.lystit.com/browse/CW-1445 */}
                        <View padding="md" width="full">
                            {/* Set your country */}
                            <Text textStyle="callout">{gettext("account.set_country")}</Text>
                            <div className={styles.dropdown}>
                                <div
                                    data-testid="country-selector-dropdown"
                                    className={styles.dropdownSelector}
                                    onClick={() => setIsSelectorOpen(!isSelectorOpen)}
                                >
                                    <Text
                                        textStyle={"body-3"}
                                        color={selectedCountry ? "primary" : "secondary"}
                                    >
                                        {selectedCountry
                                            ? selectedCountry[1]
                                            : // Country or Region
                                              gettext("burger_menu.country_selector.title")}
                                    </Text>
                                    <SVGIcon
                                        className={
                                            isSelectorOpen
                                                ? styles.dropdownOpenIndicator
                                                : styles.dropdownClosedIndicator
                                        }
                                        name="chevron-medium"
                                    />
                                </div>
                                <ul
                                    className={clsx(styles.dropdownOptions, {
                                        [styles.hide]: !isSelectorOpen,
                                    })}
                                >
                                    <>
                                        {allCountries.map((country) => (
                                            <ListItem
                                                data-testid={country[0]}
                                                key={country[0]}
                                                textStyle="body-3"
                                                as="span"
                                                onClick={() => {
                                                    setIsSelectorOpen(false);
                                                    setSelectedCountry(country);
                                                }}
                                            >
                                                {country[1]}
                                            </ListItem>
                                        ))}
                                    </>
                                </ul>
                            </div>
                            <div data-testid="set-country-button">
                                <Button
                                    title={gettext("account.set_country_btn_text")} // Set country
                                    disabled={!selectedCountry}
                                    onClick={() =>
                                        selectedCountry && onCountrySubmit(selectedCountry)
                                    }
                                    width="full"
                                />
                            </div>
                        </View>
                    </Flyout>
                </div>
            )}
        </div>
    );
}
