import _map from "lodash/map";
import React from "react";
import { useDispatch } from "react-redux";
import BuyOption from "web/react/components/buybuybuy-area/buy-option/buy-option";
import OtherBuyOptions from "web/react/components/buybuybuy-area/other-buy-options/other-buy-options";
import SizeOptionsForm from "web/react/components/buybuybuy-area/size-options-form/size-options-form";
import StickyBuyButton from "web/react/components/buybuybuy-area/sticky-buy-button/sticky-buy-button";
import ErrorModal from "web/react/components/checkout/error-modal/error-modal";
import LoadingSpinner from "web/react/components/loading-spinner/loading-spinner";
import { EventLabel, useSaveForLater } from "web/react/hooks/use-save-for-later/use-save-for-later";
import withReduxProvider from "web/react/redux-provider";
import { openCustomerCaptureOverlay } from "web/redux/ducks/customer-capture-overlay";
import analytics from "web/script/analytics/analytics";
import environment from "web/script/modules/environment";
import requester from "web/script/modules/requester";
import userProfiler from "web/script/modules/userprofiler";
import { removeCurrencyChars } from "web/script/utils/currency-symbols";
import globalEvents from "web/script/utils/global-events";
import logging from "web/script/utils/logging";
import {
    BuyBuyBuyOptionSerializer,
    ProductImageSerializer,
    BuyBuyBuyAreaSize as Size,
} from "web/types/serializers";
import styles from "./buy-options.module.css";

const OLD_CHECKOUT_ENDPOINT = "/cart/one-click-checkout/";
const NATIVE_CHECKOUT_ENDPOINT = "/checkout/now/";

enum BuyOptionsRequestErrorCode {
    GENERIC_ERROR = "generic_error",
    CHECKOUT_SIZE_NOT_IN_STOCK = "CheckoutSizeNotInStock",
}

interface BuyOptionsProps {
    isOverlay?: boolean;
    buyOptions: BuyBuyBuyOptionSerializer[];
    productId: number;
    isSavedForLater: boolean;
    sizeOptionsForm: any;
    productImages: ProductImageSerializer[];
    setRegPriceWithCurSymbol: (price: string) => void;
    setMinPriceWithCurSymbol: (price: string) => void;
    setHasMultiplePricesPerSize: (multiPrices: boolean) => void;
    setHasMultipleStoresPerSize: (multiStores: boolean) => void;
}

export function normalizeShippingCost(
    hasFreeShipping: boolean,
    shippingCost: string | null
): number {
    return !hasFreeShipping && shippingCost ? removeCurrencyChars(shippingCost) : 0;
}

// displayBuyOptions needs to be filtered based on selected size and sorted so cheapest is first
// otherBuyOptions also needs to be sorted so cheapest is first
export function updatePriceRange(
    displayBuyOptions: BuyBuyBuyOptionSerializer[],
    otherBuyOptions: BuyBuyBuyOptionSerializer[],
    setRegPrice: (price: string) => void,
    setMinPrice: (price: string) => void,
    setHasMultipleStoresPerSize: (multiStores: boolean) => void,
    setHasMultiplePricesPerSize: (multiPrices: boolean) => void
): void {
    // If there is a displayable buy option >>> show cheapest price within that size
    // Else if there is not a displayable buy option >>> show cheapest other buy option price
    if (displayBuyOptions.length > 0) {
        const minIconSizeOp = displayBuyOptions[0];
        const maxIconSizeOp = displayBuyOptions[displayBuyOptions.length - 1];

        setRegPrice(minIconSizeOp.price_with_currency_symbol);
        setMinPrice(minIconSizeOp.sale_price_with_currency_symbol);

        // Update the product range to set the multiple options per size based on selected size
        setHasMultipleStoresPerSize(displayBuyOptions.length > 1);
        setHasMultiplePricesPerSize(
            minIconSizeOp.sale_price_with_currency_symbol !==
                maxIconSizeOp.sale_price_with_currency_symbol
        );
    } else if (displayBuyOptions.length === 0 && otherBuyOptions.length > 0) {
        const minAffSizeOp = otherBuyOptions[0];
        const maxAffSizeOp = otherBuyOptions[otherBuyOptions.length - 1];

        setRegPrice(minAffSizeOp.price_with_currency_symbol);
        setMinPrice(
            minAffSizeOp.sale_price
                ? minAffSizeOp.sale_price_with_currency_symbol
                : minAffSizeOp.price_with_currency_symbol
        );

        // Update the product range to set the multiple options per size based on selected size
        setHasMultipleStoresPerSize(otherBuyOptions.length > 1);
        if (minAffSizeOp.sale_price && maxAffSizeOp.sale_price) {
            setHasMultiplePricesPerSize(minAffSizeOp.sale_price !== maxAffSizeOp.sale_price);
        } else {
            setHasMultiplePricesPerSize(minAffSizeOp.price !== maxAffSizeOp.price);
        }
    }
}

export function sortAndFilterBuyOptions(
    buyOptions: BuyBuyBuyOptionSerializer[],
    selectedSize: Size
): {
    displayBuyOptions: BuyBuyBuyOptionSerializer[];
    otherBuyOptions: BuyBuyBuyOptionSerializer[];
} {
    // Filter the buy options based on selected size
    // Then sort based on the cheapest option
    let sortedSizeOptions: BuyBuyBuyOptionSerializer[] = [];
    if (buyOptions) {
        const isNewTaxonomy =
            buyOptions.filter((buyOption) => buyOption.is_checkout && buyOption.localized_sizes)
                .length > 0;
        sortedSizeOptions = buyOptions
            .filter((option) => {
                // We only filter affiliates by size if we have new taxonomy
                if (
                    ((!option.is_checkout && isNewTaxonomy) || option.is_checkout) &&
                    option.retailer_size &&
                    selectedSize.retailer_size
                ) {
                    return (
                        option.retailer_size.display_size ===
                        selectedSize.retailer_size.display_size
                    );
                } else {
                    return true;
                }
            })
            .sort(function (a, b) {
                return (
                    // Sale_price is equal the regular price if the item is not on sale
                    Number(a.sale_price) - Number(b.sale_price)
                );
            });
    }

    const iconSizeOptions: BuyBuyBuyOptionSerializer[] = sortedSizeOptions.filter(
        (option) => option.is_checkout
    );
    const affiliateSizeOptions: BuyBuyBuyOptionSerializer[] = sortedSizeOptions.filter(
        (option) => !option.is_checkout
    );

    /*
        if we do not have any checkout buy options for this size then display the first
        buy option from the affiliates
    */
    let displayBuyOptions: BuyBuyBuyOptionSerializer[] = [];
    let otherBuyOptions: BuyBuyBuyOptionSerializer[] = [];
    if (!iconSizeOptions.length && affiliateSizeOptions.length) {
        displayBuyOptions = affiliateSizeOptions.slice(0, 1);
        otherBuyOptions = affiliateSizeOptions.slice(1);
    } else {
        displayBuyOptions = iconSizeOptions;
        otherBuyOptions = affiliateSizeOptions;
    }

    return { displayBuyOptions, otherBuyOptions };
}

export function triggerLoadingAnimations(
    initialLoaded: boolean,
    setInitialLoaded: (value: boolean) => void,
    setShowSearchAnimation: (value: boolean) => void,
    setLoaded: (value: boolean) => void,
    setBarLoading: (value: boolean) => void,
    count: number,
    setCount: (value: number) => void
): void {
    const FINAL_COUNT = 300 + Math.ceil(Math.random() * 150);

    function incrementToMax(newCount: number): void {
        const nextCount = Math.ceil(newCount + Math.random() * 10);
        setCount(nextCount);
        if (nextCount < FINAL_COUNT) {
            setTimeout(() => {
                incrementToMax(nextCount);
            }, 0);
        }
    }

    if (!initialLoaded) {
        setShowSearchAnimation(true);
        setTimeout(() => {
            setShowSearchAnimation(false);
            setInitialLoaded(true);
            setLoaded(true);
            globalEvents.trigger("buy-options-size-change");
        }, 1400);

        setTimeout(() => {
            setBarLoading(true);
        }, 50);

        incrementToMax(count);
    } else {
        setInitialLoaded(true);
        setLoaded(false);

        setTimeout(() => {
            setLoaded(true);
            globalEvents.trigger("buy-options-size-change");
        }, 600);
    }
}

export function sendBuyOptionsChangeAnalyticsEvent(buyOptions: BuyBuyBuyOptionSerializer[]): void {
    if (!buyOptions.length) {
        return;
    }

    const category = "buy_area";
    const action = "buy_options";
    const subType = `checkout.${category}.${action}`;

    // Track which buy options are available
    analytics.event(category, action, "", false, null, subType, {
        buy_area: {
            buy_options: buyOptions
                .sort(function (a, b) {
                    return Number(a.sale_price) - Number(b.sale_price);
                })
                .map((option, index) => ({
                    retailer: option.retailer_slug,
                    price: option.sale_price,
                    shipping: normalizeShippingCost(
                        option.has_free_shipping,
                        option.shipping_cost_label
                    ),
                    is_checkout: option.is_checkout,
                    has_promotion: option.promotions.length > 0, // if any promotion is applied
                    link_id: option.link_id,
                    position: index + 1, // position in which the buy option is displayed
                })),
        },
    });
}

function BuyOptions({
    isOverlay,
    buyOptions,
    sizeOptionsForm,
    productId,
    isSavedForLater,
    productImages,
    setRegPriceWithCurSymbol,
    setMinPriceWithCurSymbol,
    setHasMultiplePricesPerSize,
    setHasMultipleStoresPerSize,
}: BuyOptionsProps): React.ReactElement {
    const dispatch = useDispatch();

    const [selectedSize, setSelectedSize] = React.useState<Size | null>(null);
    const [initialLoaded, setInitialLoaded] = React.useState<boolean>(false);
    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [barLoading, setBarLoading] = React.useState<boolean>(false);
    const [count, setCount] = React.useState<number>(0);
    const optionsRef = React.useRef<HTMLDivElement>(null);
    const [displayBuyOptions, setDisplayBuyOptions] = React.useState<BuyBuyBuyOptionSerializer[]>(
        []
    );
    const [otherBuyOptions, setOtherBuyOptions] = React.useState<BuyBuyBuyOptionSerializer[]>([]);
    const [showSearchAnimation, setShowSearchAnimation] = React.useState<boolean>(false);

    const [isErrorModalOpen, setErrorModalOpen] = React.useState<boolean>(false);
    const [modalErrorTitle, setModalErrorTitle] = React.useState<string>("");
    const [modalErrorDescription, setModalErrorDescription] = React.useState<string>("");
    const [modalErrorCTAText, setModalErrorCTAText] = React.useState<string>("");
    const { toggleSaveForLater } = useSaveForLater(productId.toString(), EventLabel.OOS);

    function openErrorModal(title: string, description: string, ctaText: string): void {
        setModalErrorTitle(title);
        setModalErrorDescription(description);
        setModalErrorCTAText(ctaText);
        setErrorModalOpen(true);
    }

    function generateErrorModalText(errorCode: string): {
        title: string;
        description: string;
        ctaText: string;
    } {
        let title;
        let description;
        let ctaText;

        switch (errorCode) {
            case BuyOptionsRequestErrorCode.CHECKOUT_SIZE_NOT_IN_STOCK:
                title = "This product is out of stock";
                description =
                    "We're sorry, but this product is currently out of stock." +
                    "\n\nWe can send you a notification when it's back.";
                ctaText = "Alert me";
                break;
            case BuyOptionsRequestErrorCode.GENERIC_ERROR:
            default:
                title = "Something's gone wrong";
                description =
                    "We're sorry, but it looks like something's gone wrong." +
                    "\n\nPlease try again later.";
                ctaText = "Close";
                break;
        }
        return { title, description, ctaText };
    }

    function sendBuyActionAnalytics(options: Partial<BuyBuyBuyOptionSerializer>): Promise<any[]> {
        const label = isSavedForLater ? "save_for_later_add_to_cart" : "add_to_cart_button";

        const analyticsPromises = [
            analytics.ecommerce("ec:addProduct", {
                name: environment.get("analyticsProduct.product_canonical_slug"),
                category: environment.get("analyticsProduct.ga_category"),
                brand: environment.get("analyticsProduct.designer_slug"),
                variant: environment.get("analyticsProduct.product_uid"),
                price: options.price,
                quantity: 1,
                dimension20: environment.get("analyticsProduct.gender"),
                dimension29: environment.get("analyticsProduct.color"),
                dimension28: true,
                dimension30: false,
                dimension32: "Lyst",
                dimension34: options.raw_size,
                dimension23: options.retailer_slug,
                metric8: options.sale_discount,
                dimension31: options.is_final_sale,
                dimension27: options.has_free_shipping,
            }),
            analytics.ecommerce("ec:setAction", "add"),
            analytics.event("cart", "add_item", label, false, {
                product_id: productId,
                product_name: environment.get("analyticsProduct.product_canonical_slug"),
                price: options.price,
                currency: environment.get("currencyProps.currencyCode"),
            }),
        ];
        return Promise.all(analyticsPromises);
    }

    async function callEndpoint(
        endpoint: string,
        options: Partial<BuyBuyBuyOptionSerializer>,
        purchaseIntentSessionId: string | null,
        onSuccess: (response: Record<string, any>) => boolean,
        onError: (error: Error) => any
    ): Promise<Response> {
        const { raw_size, link_id, retailer_slug } = options;

        sendBuyActionAnalytics(options).catch((err) => {
            logging.error(err);
        });

        const requestData = {
            method: "POST",
            body: {
                buy_option: JSON.stringify({
                    icon: true,
                    raw_size,
                    link_id,
                    retailer_slug,
                    purchase_intent_session_id: purchaseIntentSessionId,
                }),
                is_base64_encoded: false,
                country: environment.get("country"),
            },
        };

        return requester(endpoint, requestData as any)
            .then(requester.toJSON)
            .then((response) => {
                if (!response.success) {
                    let error = new Error() as any;
                    error.response = response;
                    throw error;
                } else {
                    return onSuccess(response);
                }
            })
            .catch((err) => {
                logging.error(err);
                return onError(err);
            });
    }

    async function onOldCheckoutClick(
        options: Partial<BuyBuyBuyOptionSerializer>,
        purchaseIntentSessionId: string
    ): Promise<Response> {
        function onSuccess(response: any): boolean {
            window.location = response.data.url;
            return true;
        }

        function onError(err: any): any {
            analytics.event("CART", "ADD_TO_BAG_ERROR");
            let errorMessage = "Something went wrong!";

            const errorCode = err?.response?.error?.code;
            analytics.event("purchase_pdp", "errored", errorCode, false, null);

            if (err.response) {
                if (err.response["data"]) {
                    errorMessage = _map(err.response["data"], "message").join(" ");
                }

                if (
                    errorMessage.indexOf("something went wrong trying to checkout this product") >
                    -1
                ) {
                    analytics.event("cart", "add_to_cart_error", "cart_add_item_error");
                } else if (
                    errorMessage.indexOf("This product seems to no longer be in stock") > -1
                ) {
                    analytics.event("cart", "add_to_cart_error", "product_no_longer_in_stock");
                } else if (errorMessage.indexOf("You need to select an option") > -1) {
                    analytics.event("cart", "add_to_cart_error", "no_buy_option_detected");
                } else if (errorMessage.indexOf("You need to select a size") > -1) {
                    analytics.event("cart", "add_to_cart_error", "no_size_detected");
                }
            }

            const { title, description, ctaText } = generateErrorModalText(
                BuyOptionsRequestErrorCode.GENERIC_ERROR
            );
            openErrorModal(title, description, ctaText);
            return err;
        }

        return callEndpoint(
            OLD_CHECKOUT_ENDPOINT,
            options,
            purchaseIntentSessionId,
            onSuccess,
            onError
        );
    }

    async function onNativeCheckoutClick(
        options: Partial<BuyBuyBuyOptionSerializer>,
        purchaseIntentSessionId: string
    ): Promise<Response> {
        function onSuccess(response: any): boolean {
            window.location = response.data.url;
            return true;
        }

        function onError(error: any): void {
            const errorCode = error?.response?.error?.code;
            analytics.event("purchase_pdp", "errored", errorCode, false, null);

            const { title, description, ctaText } = generateErrorModalText(errorCode);

            openErrorModal(title, description, ctaText);
        }

        return callEndpoint(
            NATIVE_CHECKOUT_ENDPOINT,
            options,
            purchaseIntentSessionId,
            onSuccess,
            onError
        );
    }

    function modalErrorCTA(): void {
        setErrorModalOpen(false);
        if (modalErrorCTAText === "Alert me") {
            if (!userProfiler.isLoggedIn()) {
                dispatch(
                    openCustomerCaptureOverlay({
                        productId: productId,
                        captureType: "stock_alert_lead_return",
                    })
                );
            } else {
                toggleSaveForLater();
            }
        }
    }

    function onSizeChange(newSize: Size): void {
        setSelectedSize(newSize);

        // get buy options for selected size
        const { displayBuyOptions, otherBuyOptions } = sortAndFilterBuyOptions(buyOptions, newSize);

        setDisplayBuyOptions(displayBuyOptions);
        setOtherBuyOptions(otherBuyOptions);

        if (displayBuyOptions.length > 0) {
            sendBuyOptionsChangeAnalyticsEvent(displayBuyOptions);
        }

        // update the price range
        updatePriceRange(
            displayBuyOptions,
            otherBuyOptions,
            setRegPriceWithCurSymbol,
            setMinPriceWithCurSymbol,
            setHasMultipleStoresPerSize,
            setHasMultiplePricesPerSize
        );

        // trigger fake loading animations
        triggerLoadingAnimations(
            initialLoaded,
            setInitialLoaded,
            setShowSearchAnimation,
            setLoaded,
            setBarLoading,
            count,
            setCount
        );

        // send size change analytics
        const category = "size_picker";
        const action = "size_change";
        const subType = `checkout.${category}.${action}`;

        // Track which size the user was on, and what they switched to
        analytics.event(category, action, "", false, null, subType, {
            size_picker: {
                size_change: {
                    to_size: newSize.raw_size,
                },
            },
        });
    }

    return (
        <>
            <ErrorModal
                title={modalErrorTitle}
                description={modalErrorDescription}
                isOpen={isErrorModalOpen}
                onClose={() => setErrorModalOpen(false)}
                buttonCTAText={modalErrorCTAText}
                buttonCTA={modalErrorCTA}
            />
            {!isOverlay && <StickyBuyButton targetRef={optionsRef} />}
            <SizeOptionsForm
                action={sizeOptionsForm.action}
                csrfToken={sizeOptionsForm.csrfToken}
                country={sizeOptionsForm.country}
                returnUrl={sizeOptionsForm.returnUrl}
                availableSizes={sizeOptionsForm.availableSizes}
                sizes={sizeOptionsForm.sizes}
                sizeGuideLink={sizeOptionsForm.sizeGuideLink}
                onSizeChange={onSizeChange}
                userDefaultSchema={sizeOptionsForm.userDefaultSchema}
                schemas={sizeOptionsForm.schemas}
                productImages={productImages}
            />
            <div className={`${styles.options} buybuybuy-options`} ref={optionsRef}>
                {/* TODO: Refactor buybuybuy-options className when migrating sticky buy button */}
                {selectedSize && (
                    <>
                        {showSearchAnimation && (
                            <div className={styles.search}>
                                <h5 className={styles.text}>
                                    {"Searching "}
                                    <span className={styles.count}>{count}</span>
                                    {" stores..."}
                                </h5>
                                <div
                                    className={`${styles.bar} ${
                                        barLoading ? styles.barLoading : ""
                                    }`}
                                />
                            </div>
                        )}
                        {initialLoaded && !loaded && (
                            <div className={styles.spinner}>
                                <LoadingSpinner inline type="highlight" />
                            </div>
                        )}
                        {loaded && (
                            <div className={styles.options}>
                                {displayBuyOptions &&
                                    displayBuyOptions.map((iconOption, idx) => (
                                        <BuyOption
                                            key={idx}
                                            initialExpanded={idx === 0}
                                            buyBuyBuyOption={iconOption}
                                            buyOptionPosition={idx + 1}
                                            onOldCheckoutClick={onOldCheckoutClick}
                                            onNativeCheckoutClick={onNativeCheckoutClick}
                                            hasOtherBuyOptions={otherBuyOptions.length > 0}
                                            isLastBuyOption={idx === displayBuyOptions.length - 1}
                                        />
                                    ))}
                                {otherBuyOptions.length > 0 && (
                                    <OtherBuyOptions options={otherBuyOptions} />
                                )}
                            </div>
                        )}
                    </>
                )}
            </div>
        </>
    );
}

export default withReduxProvider(BuyOptions);
