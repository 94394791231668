import { Form, Formik, FormikConfig, FormikState, FormikValues } from "formik";
import React from "react";
import { useNavigate } from "react-router-dom";
import { SocialAuthButtons } from "web/react/components/buttons/social-auth-button";
import Conditional from "web/react/components/conditional/conditional";
import { Divider } from "web/react/components/divider";
import { FormikCheckbox, FormikTextField } from "web/react/components/forms/formik";
import { Button } from "web/react/emo/button";
import { Heading } from "web/react/emo/heading";
import { Text } from "web/react/emo/text/text";
import { VStack } from "web/react/emo/v-stack";
import { View } from "web/react/emo/view";
import { useDomViewport } from "web/react/hooks/use-dom-viewport/use-dom-viewport";
import analytics from "web/script/analytics/analytics";
import { gettext, gettextNoop } from "web/script/modules/django-i18n";
import { getHtmlProps } from "web/script/modules/html-tag-props";
import { getUrl } from "web/script/routing/getUrl";
import useSoftSignupForm, { SoftSignupFormValues } from "./use-soft-signup-form";

// ensuring we bundle the T&C i18n keys
gettextNoop("account.register.legal_agreement.sign_up_opt_in_terms");
gettextNoop("account.register.legal_agreement.sign_up_opt_in_terms_us_version");

interface SoftSignupFormProps {
    email: string;
    setEmail: (email: string) => void;
}

function SoftSignupForm({ email, setEmail }: SoftSignupFormProps): JSX.Element {
    const navigate = useNavigate();
    const {
        validate,
        onSubmit,
        signInLink,
        showSignInLink,
        title,
        subtitle,
        submitText,
        initialValues,
        captureType,
        requiresOptIn,
        socialAuthProps,
    } = useSoftSignupForm({ email, onExistingEmail });
    const { isTabletViewport, isDesktopViewport } = useDomViewport();

    function onExistingEmail(newEmail: string): void {
        setEmail(newEmail);
        navigate("/login");
    }

    return (
        <VStack spacing={"sm"}>
            <Heading as={"h1"} textStyle={{ sm: "title-1", lg: "large-title-2" }}>
                {title}
            </Heading>
            <Text textStyle={"body-2"} color={"secondary"} as={"p"}>
                {subtitle}
            </Text>
            <VStack spacing={isTabletViewport || isDesktopViewport ? "md" : "xxs"}>
                <SignupForm initialValues={initialValues} onSubmit={onSubmit} validate={validate}>
                    {({ isSubmitting }): JSX.Element => (
                        <>
                            <EmailInput captureType={captureType} />
                            <Conditional check={!!showSignInLink}>
                                <Text textStyle={"body-1"} as={"p"}>
                                    {
                                        // "Looks like you are already a member!"
                                        gettext("account.register.already_member.label")
                                    }{" "}
                                    <a href={signInLink} target={"_blank"} rel="noreferrer">
                                        {
                                            // "Log in here"
                                            gettext("account.register.login_here.link")
                                        }
                                    </a>
                                </Text>
                            </Conditional>
                            <MarketingCheckbox
                                requiresOptIn={requiresOptIn}
                                captureType={captureType}
                            />
                            <div>
                                <SubmitButton submitText={submitText} disabled={isSubmitting} />
                            </div>
                        </>
                    )}
                </SignupForm>
                <>
                    <Divider>
                        <>
                            {/* or */}
                            {gettext("general.or")}
                        </>
                    </Divider>
                    <SocialAuthButtons {...socialAuthProps} />
                </>
                <LegalText requiresOptIn={requiresOptIn} />
            </VStack>
        </VStack>
    );
}

interface SignupFormProps {
    children: (props: FormikState<FormikValues>) => JSX.Element;
    initialValues: SoftSignupFormValues;
    onSubmit: NonNullable<FormikConfig<SoftSignupFormValues>["onSubmit"]>;
    validate: NonNullable<FormikConfig<SoftSignupFormValues>["validate"]>;
}

function SignupForm({ children, initialValues, onSubmit, validate }: SignupFormProps): JSX.Element {
    return (
        <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validate={validate}
            validateOnChange={false}
            validateOnBlur={false}
        >
            {(props): JSX.Element => {
                return (
                    <Form noValidate>
                        <View>
                            <VStack spacing="sm">{children(props)}</VStack>
                        </View>
                    </Form>
                );
            }}
        </Formik>
    );
}

interface LegalTextProps {
    requiresOptIn: boolean;
}

export function LegalText({ requiresOptIn }: LegalTextProps): JSX.Element {
    const urlProps = {
        terms_and_conditions_link_props: getHtmlProps({
            href: getUrl("helpTermsAndConditions"),
            target: "_blank",
        }),
        privacy_policy_link_props: getHtmlProps({
            href: getUrl("helpPrivacyPolicy"),
            target: "_blank",
        }),
    };

    return (
        <Text textStyle="body-3" color="secondary">
            <span
                dangerouslySetInnerHTML={{
                    // "By creating an account, you consent to Lyst’s Terms & Conditions.
                    // To learn more about how Lyst uses and protects your personal data, please read Lyst’s Privacy Policy."
                    __html: gettext(
                        requiresOptIn
                            ? "account.register.legal_agreement.sign_up_opt_in_terms"
                            : "account.register.legal_agreement.sign_up_opt_in_terms_us_version",
                        urlProps
                    ),
                }}
            />
        </Text>
    );
}

interface MarketingCheckboxProps {
    requiresOptIn: boolean;
    captureType: string;
}

function MarketingCheckbox({ requiresOptIn, captureType }: MarketingCheckboxProps): JSX.Element {
    return (
        <Conditional check={requiresOptIn}>
            <FormikCheckbox
                name="marketing_emails"
                onChange={(e) => {
                    analytics.event(
                        captureType,
                        e.target.checked ? "tick_box" : "untick_box",
                        "tickbox_marketing"
                    );
                }}
                // "I would like to hear about products, services, and sales, including personalized email
                // alerts from Lyst. You can unsubscribe at any time."
                label={gettext("account.register.legal_agreement.sign_up_checkbox_label")}
            />
        </Conditional>
    );
}

interface EmailInputProps {
    captureType: string;
}

function EmailInput({ captureType }: EmailInputProps): JSX.Element {
    return (
        <FormikTextField
            name="email"
            type="email"
            // "Email"
            label={gettext("settings.account.form.email_label")}
            // "Enter your email address to login or register"
            placeholder={gettext("account.register.form.enter_email.placeholder")}
            onFocus={() => {
                analytics.event(captureType, "fill_field", "field_email");
            }}
            autoCapitalize="off"
            autoFocus={false}
            required={true}
        />
    );
}

interface SubmitButtonProps {
    submitText: string;
    disabled: boolean;
    className?: string;
}

function SubmitButton({ submitText, disabled }: SubmitButtonProps): JSX.Element {
    return <Button width="full" disabled={disabled} type="submit" title={submitText} />;
}

SoftSignupForm.SubmitButton = SubmitButton;
SoftSignupForm.EmailInput = EmailInput;
SoftSignupForm.MarketingCheckbox = MarketingCheckbox;
SoftSignupForm.LegalText = LegalText;
SoftSignupForm.SignupForm = SignupForm;
export default SoftSignupForm;
