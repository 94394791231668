import React from "react";
import { InputComponentProps } from "web/react/components/input-components/types";
import { ValidatedTextInput } from "web/react/components/validated-text-input/validated-text-input";
import { useIdPrefix } from "web/react/hooks/use-id-prefix/use-id-prefix";
import { gettext } from "web/script/modules/django-i18n";

export function handleOnInput(event: React.KeyboardEvent<HTMLInputElement>): void {
    const target = event.currentTarget;
    let position = target.selectionStart;
    target.value = target.value.toUpperCase();
    target.setSelectionRange(position, position);
}

export const POSTCODE_REGEX = "^[A-Z]{1,2}\\d[A-Z\\d]? ?\\d[A-Z]{2}$";

export function Postcode({
    fieldData,
    idPrefix,
    onChange,
    setError,
}: InputComponentProps): React.ReactElement {
    const componentName = "postcode";
    const id = useIdPrefix(idPrefix, componentName);

    return (
        <ValidatedTextInput
            className={"addressInputComponent"}
            displayName={gettext("input.address_input.postcode.field_name")}
            error={fieldData.error}
            inputProps={{
                id,
                name: componentName,
                onInput: handleOnInput,
                pattern: POSTCODE_REGEX,
                value: fieldData.value,
            }}
            onChange={onChange}
            setError={setError}
            validation={["patternMismatch", "valueMissing"]}
        />
    );
}
