import includes from "lodash/includes";
import React from "react";
import {
    GenericTextInput,
    GenericTextInputProps,
} from "web/react/components/inputs-base-components";
import { gettext } from "web/script/modules/django-i18n";
import styles from "./validated-text-input.module.css";

export function getValidationErrorMessage(
    validationError: keyof ValidityState,
    displayName: string
): string {
    const VALIDATION_ERROR_MESSAGE_TOKENS: { [Property in keyof ValidityState]?: string } = {
        patternMismatch: gettext("input.validation.invalid", { field_name: displayName }),
        typeMismatch: gettext("input.validation.invalid", { field_name: displayName }),
        valueMissing: gettext("input.validation.value_missing", { field_name: displayName }),
    };

    return VALIDATION_ERROR_MESSAGE_TOKENS[validationError] || "";
}

type Validation = (keyof ValidityState)[];

export interface InputProps extends React.HTMLProps<HTMLInputElement> {
    type?: GenericTextInputProps["type"];
}
export interface ValidatedTextInputProps {
    className?: string;
    displayName: string;
    displayNameExtra?: string;
    error: string;
    inputProps: InputProps;
    labelType?: "fixed" | "floating";
    onChange?: (value: string) => void;
    setError: (displayText: string) => void;
    validation?: Validation;
    icon?: JSX.Element;
}

export function ValidatedTextInput({
    className,
    displayName,
    displayNameExtra,
    error,
    inputProps,
    labelType = "fixed",
    onChange,
    setError,
    icon,
    validation = [],
}: ValidatedTextInputProps): React.ReactElement {
    function validate(validity: ValidityState): void {
        if (!validity.valid) {
            const failedChecks = validation.filter(
                (validationCheck: string) => validity[validationCheck]
            );

            const validationErrorMessage = getValidationErrorMessage(failedChecks[0], displayName);

            setError(validationErrorMessage); // Only show the first error message, others are suppressed
        } else {
            setError("");
        }
    }

    function handleOnBlur(event: React.FocusEvent<HTMLInputElement>): void {
        if (inputProps.onBlur) {
            inputProps.onBlur(event);
        }
        const { validity } = event.target;

        validate(validity);
    }

    function handleOnChange(event: React.ChangeEvent<HTMLInputElement>): void {
        if (inputProps.onChange) {
            inputProps.onChange(event);
        }
        if (onChange) {
            onChange(event.target.value);
        }
    }

    function handleOnFocus(event: React.FocusEvent<HTMLInputElement>): void {
        if (inputProps.onFocus) {
            inputProps.onFocus(event);
        }
        clearErrorText();
    }

    function handleOnInvalid(event: React.InvalidEvent<HTMLInputElement>): void {
        const { validity } = event.currentTarget;
        validate(validity);
    }

    function clearErrorText(): void {
        setError("");
    }

    return (
        <div className={className}>
            {(labelType === "fixed" || inputProps.value) && (
                <label className={styles.label} htmlFor={inputProps.id}>
                    {displayName}
                    {displayNameExtra}
                </label>
            )}
            <GenericTextInput
                {...inputProps}
                onChange={handleOnChange}
                onFocus={handleOnFocus}
                onBlur={handleOnBlur}
                onInvalid={handleOnInvalid}
                required={includes(validation, "valueMissing")}
                error={error}
                icon={icon}
            />
        </div>
    );
}
