import clsx from "clsx";
import _escape from "lodash/escape";
import PropTypes from "prop-types";
import React from "react";
import LeadLink from "web/react/components/lead-link/lead-link";
import SVGIcon from "web/react/components/svg-icon/svg-icon";
import { gettext } from "web/script/modules/django-i18n";
import { getHtmlProps } from "web/script/modules/html-tag-props";
import { ShippingOptionPropType } from "web/types/buy-option";
import style from "./shipping.module.css";

function Shipping({
    className,
    freeShipping,
    freeShippingUrl,
    leadUrl,
    retailerName,
    shouldShowExtraShipping,
    extraShipping,
}) {
    const seeMoreOnSpanProps = {
        class: style.seeMoreOn,
    };
    const seeMoreOnRetailerSpanProps = {
        class: style.link,
    };
    // we escape retailerName, and so mark this string as available for dangerouslySetInnerHTML
    const seeMoreOnRetailerSafeHtml = {
        __html: gettext("in_stock_product.see_more_on_retailer.in_spans", {
            see_more_span_props: getHtmlProps(seeMoreOnSpanProps),
            retailer_name_span_props: getHtmlProps(seeMoreOnRetailerSpanProps),
            retailer_name: _escape(retailerName),
        }),
    };

    return (
        <div className={clsx(className, style.wrapper)}>
            {freeShipping && (
                <div className={style.freeShipping}>
                    <div className={style.icon}>
                        <SVGIcon name="truck" />
                    </div>
                    {freeShippingUrl ? (
                        <a
                            className={style.shippingLabel}
                            href={freeShippingUrl}
                            dangerouslySetInnerHTML={{ __html: freeShipping.html_message }}
                        />
                    ) : (
                        <span
                            className={style.shippingLabel}
                            dangerouslySetInnerHTML={{ __html: freeShipping.html_message }}
                        />
                    )}
                </div>
            )}
            {shouldShowExtraShipping && extraShipping.shipping_options && (
                <section>
                    <p>
                        {gettext("in_stock_product.shipping_by_retailer", {
                            retailer_name: extraShipping.retailer_name,
                        })}
                    </p>
                    {extraShipping.shipping_options.map((option, i) => {
                        return (
                            <div key={i} className={style.option}>
                                <div className={style.optionPrice}>
                                    <em>{option.price}</em>
                                </div>
                                <div>
                                    <div>
                                        <em>{option.name}</em>
                                    </div>
                                    <div className="grey-text">
                                        {option.estimated_delivery}
                                        {option.service && <>{option.service}</>}
                                    </div>
                                    {option.conditions && (
                                        <div>
                                            <span className="glyph glyph-small glyph-tag-red"></span>
                                            <span>{option.conditions}</span>
                                        </div>
                                    )}
                                </div>
                            </div>
                        );
                    })}
                </section>
            )}
            {shouldShowExtraShipping && extraShipping.returns_method && (
                <section>
                    <h2>{"Returns Policy"}</h2>
                    <p className="mt10">
                        {gettext("in_stock_product.return_by_retailer", {
                            retailer_name: extraShipping.retailer_name,
                        })}
                    </p>
                    <p className="mb5">
                        {"Return Period: "} <em>{extraShipping.returns_period}</em>
                    </p>
                    <p className="mb5 mt5">
                        {"Cost: "} <em>{extraShipping.returns_price}</em>
                    </p>
                    <p className="mb5 mt5">
                        {"Method: "} <em>{extraShipping.returns_method}</em>
                    </p>
                </section>
            )}
            <div className={style.linkHolder}>
                <LeadLink reason="retailer-link" href={leadUrl}>
                    <span dangerouslySetInnerHTML={seeMoreOnRetailerSafeHtml} />
                </LeadLink>
                <SVGIcon name="round-chevron" />
            </div>
        </div>
    );
}

export default Shipping;

Shipping.propTypes = {
    className: PropTypes.string,
    freeShipping: ShippingOptionPropType,
    freeShippingUrl: PropTypes.string,
    leadUrl: PropTypes.string,
    retailerName: PropTypes.string,
    shouldShowExtraShipping: PropTypes.bool,
    extraShipping: PropTypes.object,
};

Shipping.defaultProps = {
    freeShipping: null,
};
