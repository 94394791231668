import React from "react";
import { InputComponentProps } from "web/react/components/input-components/types";
import { ValidatedTextInput } from "web/react/components/validated-text-input/validated-text-input";
import { useIdPrefix } from "web/react/hooks/use-id-prefix/use-id-prefix";
import { gettext } from "web/script/modules/django-i18n";

// regex from http://www.regular-expressions.info/email.html
export const EMAIL_REGEX =
    "[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?";

export function EmailInput({
    className,
    fieldData,
    idPrefix,
    onChange,
    setError,
}: InputComponentProps): React.ReactElement {
    const componentName = "email";
    const id = useIdPrefix(idPrefix, componentName);

    return (
        <ValidatedTextInput
            className={className}
            displayName={gettext("input.email_input.field_name")}
            error={fieldData.error}
            inputProps={{
                id,
                name: componentName,
                pattern: EMAIL_REGEX,
                type: "email",
                value: fieldData.value,
            }}
            onChange={onChange}
            setError={setError}
            validation={["patternMismatch", "valueMissing"]}
        />
    );
}
