import React from "react";
import { Conditional } from "web/react/components/conditional";
import { useProductCardContext } from "web/react/components/product-card/react";
import { ProductCardLink } from "web/react/components/product-card/react/product-card-link";
import { Retailer, SponsoredByRetailer } from "web/react/emo/retailer/retailer";
import { BaseText } from "web/react/emo/shared/components/base-text";
import { Text } from "web/react/emo/text";
import { VStack } from "web/react/emo/v-stack";
import { View } from "web/react/emo/view";
import analytics from "web/script/analytics/analytics";
import { gettext } from "web/script/modules/django-i18n";
import environment from "web/script/modules/environment";
import globals from "web/script/modules/globals";
import history from "web/script/utils/history";
import * as styles from "./product-details.css";

interface ProductCardDetailsProps {
    showRetailer?: boolean;
    showPromo?: boolean;
    feedType?: string;
    analyticsCategory?: string;
    analyticsEventLabel?: string;
    reason?: string;
    shouldRenderLink?: boolean;
    showSponsoredMessaging?: boolean;
}

export function ProductDetails({
    showRetailer = true,
    showPromo = true,
    feedType,
    analyticsCategory = "product_card",
    analyticsEventLabel,
    reason,
    shouldRenderLink = true,
    showSponsoredMessaging = false,
}: ProductCardDetailsProps): JSX.Element {
    const {
        id,
        designer_name: brandName,
        short_description: shortDescription,
        retailer_name: retailerName,
        sale_discount: saleDiscount,
        in_stock: inStock,
        show_retailer_as_affiliate: showRetailerAsAffiliate,
        related_products_feed_link: relatedProductsFeedLink,
    } = useProductCardContext();

    const retailerLabelVariant = environment.getFeature("cw_retailer_label_in_product_card");
    const isRetailerLabelOverlay = retailerLabelVariant === "open_overlay";
    const isRetailerLabelLeadGen = retailerLabelVariant === "lead_gen";
    const isRetailerLabelControl = !isRetailerLabelOverlay && !isRetailerLabelLeadGen;

    const promo = getPromoLabel();
    const seoMoreLikeThisInProductCards = environment.getFeature(
        "seo_more_like_this_in_product_cards"
    );

    // TODO CW-318 move this logic of getting the promo on the BE same as mobile_api
    function getPromoLabel(): string | undefined {
        if (!inStock) return gettext("in_stock_product.compare_prices.out_of_stock_option");
        if (saleDiscount) return gettext("product_card.promo_label.sale");
    }

    function handleShortDescriptionClick(): void {
        analytics.event(analyticsCategory, "short description", analyticsEventLabel, false, {
            product_id: id,
            designer_name: brandName,
            retailer_name: retailerName,
            in_stock: inStock,
        });
    }

    function handleRetailerClick(): void {
        analytics.event(analyticsCategory, "retailer", analyticsEventLabel);
    }

    const shortDescriptionLineLimit = analyticsEventLabel !== "curated_ads" ? 1 : 2;

    async function handleRelatedProductsFeedLink(
        event: React.MouseEvent<HTMLElement>,
        href: string
    ): Promise<void> {
        analytics.event(analyticsCategory, "more_like_this");

        // Let the link open the related feed page
        if (seoMoreLikeThisInProductCards === "feed_page") {
            return;
        }

        // Open an overlay with the related products
        event.preventDefault();
        history.pushOverlay("related-products-overlay", {
            newURL: new URL(`${globals.window.location.origin}${href}`),
        });
    }

    return (
        <View>
            <VStack spacing="xxs">
                <VStack spacing="xxxs">
                    <Conditional check={Boolean(shouldRenderLink)}>
                        <ProductCardLink
                            reason={reason || "text"}
                            onClick={handleShortDescriptionClick}
                            feedType={feedType}
                        >
                            <VStack spacing="none">
                                <Text
                                    as="span"
                                    textStyle="callout-small"
                                    color={"secondary"}
                                    lineLimit={1}
                                >
                                    {brandName}
                                </Text>
                                <Conditional check={!!shortDescription}>
                                    <Text
                                        as="span"
                                        textStyle="body-3"
                                        color={"secondary"}
                                        lineLimit={shortDescriptionLineLimit}
                                        aria-description={gettext(
                                            "product_card.product_description.label"
                                        )}
                                    >
                                        {shortDescription}
                                    </Text>
                                </Conditional>
                            </VStack>
                        </ProductCardLink>
                    </Conditional>
                    <Conditional check={Boolean(!shouldRenderLink)}>
                        <div onClick={handleShortDescriptionClick} className={styles.cursorPointer}>
                            <VStack spacing="none">
                                <Text
                                    as="span"
                                    textStyle="callout-small"
                                    color={"secondary"}
                                    lineLimit={1}
                                >
                                    {brandName}
                                </Text>
                                <Conditional check={!!shortDescription}>
                                    <Text
                                        as="span"
                                        textStyle="body-3"
                                        color={"secondary"}
                                        lineLimit={shortDescriptionLineLimit}
                                        aria-description={gettext(
                                            "product_card.product_description.label"
                                        )}
                                    >
                                        {shortDescription}
                                    </Text>
                                </Conditional>
                            </VStack>
                        </div>
                    </Conditional>
                    <Conditional
                        check={Boolean(!isRetailerLabelControl && showRetailer && retailerName)}
                    >
                        <ProductCardLink
                            reason={"retailer"}
                            onClick={handleShortDescriptionClick}
                            feedType={feedType}
                            predicate={isRetailerLabelLeadGen}
                        >
                            <div onClick={handleRetailerClick} data-testid="retailer">
                                <Text as="span" textStyle="callout-small" color={"secondary"}>
                                    {showSponsoredMessaging ? (
                                        <SponsoredByRetailer
                                            retailerName={retailerName}
                                            showLinkIcon={showRetailerAsAffiliate}
                                        />
                                    ) : (
                                        <Retailer
                                            retailerName={retailerName as string}
                                            fromSpanStyle={"capitalize"}
                                            showLinkIcon={showRetailerAsAffiliate}
                                        />
                                    )}
                                </Text>
                            </div>
                        </ProductCardLink>
                    </Conditional>
                    <Conditional
                        check={Boolean(isRetailerLabelControl && showRetailer && retailerName)}
                    >
                        <div onClick={handleRetailerClick} data-testid="retailer">
                            <Text as="span" textStyle="callout-small" color={"secondary"}>
                                {showSponsoredMessaging ? (
                                    <SponsoredByRetailer
                                        retailerName={retailerName}
                                        showLinkIcon={showRetailerAsAffiliate}
                                    />
                                ) : (
                                    <Retailer
                                        retailerName={retailerName as string}
                                        fromSpanStyle={"capitalize"}
                                        showLinkIcon={showRetailerAsAffiliate}
                                    />
                                )}
                            </Text>
                        </div>
                    </Conditional>
                </VStack>
                <Conditional check={Boolean(showPromo && promo && !relatedProductsFeedLink)}>
                    <BaseText as="span" textStyle={"caption-2"} color={"secondaryBrand"} upperCase>
                        {promo}
                    </BaseText>
                </Conditional>
                <Conditional check={!!relatedProductsFeedLink}>
                    <a
                        href={relatedProductsFeedLink as string}
                        onClick={(event) =>
                            handleRelatedProductsFeedLink(event, relatedProductsFeedLink as string)
                        }
                    >
                        <BaseText
                            as="span"
                            textStyle={"caption-2"}
                            color={"secondaryBrand"}
                            upperCase
                        >
                            {`${gettext("product.buy_area.more_like_this.label")} ➜`}
                        </BaseText>
                    </a>
                </Conditional>
            </VStack>
        </View>
    );
}
