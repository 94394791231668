import loader from "./loader";

window.pintrk = window.pintrk || function () {};

function insertNodeBeforeScript(node) {
    const head = document.getElementsByTagName("head")[0];
    const script = document.createElement("script");
    head.appendChild(script);
    script.parentNode.insertBefore(node, script);
}

const load = loader(() => {
    window.pintrk = function (...args) {
        window.pintrk.queue.push(Array.prototype.slice.call(args));
    };
    window.pintrk.queue = [];
    window.pintrk.version = "3.0";

    var script = document.createElement("script");
    script.async = !0;
    script.src = "https://s.pinimg.com/ct/core.js";
    insertNodeBeforeScript(script);

    // Fire base code events once per pageview
    window.pintrk("load", "2618393217634");
    window.pintrk("page");
});

export default function () {
    load();

    window.pintrk("track", "pagevisit");
}
