import clsx from "clsx";
import React from "react";
import { HStack } from "web/react/emo/h-stack";
import { Heading } from "web/react/emo/heading";
import { ListItem } from "web/react/emo/list-item";
import { Text } from "web/react/emo/text";
import { VStack } from "web/react/emo/v-stack";
import { View } from "web/react/emo/view";
import { AZBlock, BrandsLayoutSerializer } from "web/types/serializers";
import * as styles from "./brands.css";

function AZBlockItem({ title, items_list }: AZBlock): React.ReactElement {
    return (
        <View width="full">
            <VStack spacing="sm">
                <div id={`${title}`}>
                    <Heading as="h1" textStyle="xl-large-title">
                        {title}
                    </Heading>
                </div>
                <ul className={styles.multiColumnList}>
                    {items_list.map((item, i) => (
                        <ListItem className={styles.listItem} key={`${i}-${item.title}`}>
                            <a href={item.link}>
                                <Text textStyle="body-3">{item.title}</Text>
                            </a>
                        </ListItem>
                    ))}
                </ul>
            </VStack>
        </View>
    );
}

type BrandsPageProps = Omit<
    BrandsLayoutSerializer,
    | "brands_type"
    | "featured_link_text"
    | "all_of_brand_link_text"
    | "is_featured"
    | "standard_url"
    | "featured_url"
    | "show_featured_tab"
>;

export function BrandsPage({
    main_heading,
    az_list,
    az_navigation,
    brand_pages,
    subtitle,
}: BrandsPageProps): React.ReactElement {
    return (
        <View className={styles.layoutContainer}>
            <View width="full">
                <VStack align={"center"}>
                    <Heading as="h1" textStyle="large-title">
                        {main_heading}
                    </Heading>
                    {subtitle && (
                        <Heading as="h2" textStyle="subhead">
                            {subtitle}
                        </Heading>
                    )}
                </VStack>
                <VStack spacing="lg">
                    <ul className={styles.pageTabs}>
                        {brand_pages.map((page, i) => (
                            <li
                                className={clsx(styles.pageTabItem, {
                                    [styles.active]: page.is_active,
                                })}
                                key={`${i}-${page.page_name}`}
                            >
                                <a href={page.page_url}>
                                    <Text textStyle="body-3">{page.page_name}</Text>
                                </a>
                            </li>
                        ))}
                    </ul>
                    <HStack spacing="none" wrap>
                        {az_navigation.links.map((link) => (
                            <ListItem
                                as="a"
                                key={link.title}
                                href={`#${link.title}`}
                                className={clsx(styles.azLetter, {
                                    [styles.disabledLink]: link.disabled,
                                })}
                                label={link.title}
                                labelClassName={styles.azLetterText}
                                textColor={link.disabled ? "secondary" : "primary"}
                                textStyle="body-3"
                            />
                        ))}
                    </HStack>
                    <View className={styles.divider} />
                    {az_list.blocks.map((block, i) => (
                        <AZBlockItem {...block} key={`${i}-${block.title}`}></AZBlockItem>
                    ))}
                </VStack>
            </View>
        </View>
    );
}
