import React from "react";
import { InputComponentProps } from "web/react/components/input-components/types";
import { ValidatedTextInput } from "web/react/components/validated-text-input/validated-text-input";
import { useIdPrefix } from "web/react/hooks/use-id-prefix/use-id-prefix";
import { gettext } from "web/script/modules/django-i18n";

export const ZIPCODE_REGEX = "^\\b\\d{5}(-\\d{4})?\\b$";

export function ZipCode({
    fieldData,
    idPrefix,
    onChange,
    setError,
}: InputComponentProps): React.ReactElement {
    const componentName = "zipCode";
    const id = useIdPrefix(idPrefix, componentName);

    return (
        <ValidatedTextInput
            className={"addressInputComponent"}
            displayName={gettext("input.address_input.zip_code.field_name")}
            error={fieldData.error}
            inputProps={{
                id,
                inputMode: "numeric",
                name: componentName,
                pattern: ZIPCODE_REGEX,
                value: fieldData.value,
            }}
            onChange={onChange}
            setError={setError}
            validation={["patternMismatch", "valueMissing"]}
        />
    );
}
