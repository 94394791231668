import React from "react";
import LazyImage from "web/react/components/lazy-load-image/lazy-load-image";
import { EditorialDetails } from "web/react/emo/editorial-details";
import { View } from "web/react/emo/view";
import { useDomViewport } from "web/react/hooks/use-dom-viewport/use-dom-viewport";
import { useStoryAnalytics } from "web/react/hooks/use-story-analytics";
import { SecondaryStorySerializer } from "web/types/serializers";
import * as styles from "./secondary-story.css";

interface SecondaryStoryProps extends SecondaryStorySerializer {
    railPosition: number;
    position: number;
    lazy?: boolean;
    alt?: string;
}

export function SecondaryStory({
    id: componentId,
    title,
    caption,
    url,
    component_label: componentLabel,
    desktop_image_url: desktopImage,
    mobile_image_url: mobileImage,
    position,
    railPosition,
    lazy = false,
    alt,
}: SecondaryStoryProps): React.ReactElement {
    const { isMobileViewport } = useDomViewport();

    const lazyImageSrc = isMobileViewport ? mobileImage : desktopImage;

    const { containerRef, sendClickEvent } = useStoryAnalytics({
        label: "secondary_story",
        componentId,
        componentLabel,
        position,
        isSponsored: false,
        railPosition,
    });

    return (
        <div ref={containerRef} className={styles.storyCard} data-testid="secondary-story">
            {lazy ? (
                <a href={url} onClick={() => sendClickEvent("image")} data-testid={"image"}>
                    <LazyImage src={lazyImageSrc} className={styles.image} alt={alt} />
                </a>
            ) : (
                <a href={url} onClick={() => sendClickEvent("image")} data-testid={"image"}>
                    <picture>
                        <source
                            data-testid="mobile-image-source"
                            media={styles.mobileMedia}
                            srcSet={mobileImage}
                        />
                        <source
                            data-testid="desktop-image-source"
                            media={styles.desktopMedia}
                            srcSet={desktopImage}
                        />
                        <img
                            data-test-id="image"
                            src={mobileImage}
                            className={styles.image}
                            alt={alt}
                        />
                    </picture>
                </a>
            )}
            <View width="full">
                <EditorialDetails
                    isBodyClickable={isMobileViewport}
                    variant="compact"
                    details={{
                        isSponsored: false,
                        title,
                        caption,
                        url,
                    }}
                    onCtaClick={() => sendClickEvent("cta")}
                />
            </View>
        </div>
    );
}
