import React, { useRef } from "react";
import LeadLink from "web/react/components/lead-link/lead-link";
import { useProductCardContext } from "./product-card";

// Curated Ads products lack the features and analytics that most product cards have.
interface ProductCardLinkCuratedAdProps {
    collectionId: number;
    className?: string;
    children?: any;
    onClick: () => void;
}

export function ProductCardLinkCuratedAd({
    collectionId,
    className,
    children,
    onClick,
}: ProductCardLinkCuratedAdProps): React.ReactElement | null {
    const {
        id,
        lead_url: leadURL,
        affiliate_promo_url: affiliatePromoUrl,
    } = useProductCardContext();

    const visibleRef = useRef<any>(null);

    return (
        <div className={className}>
            <div ref={visibleRef}>
                <LeadLink
                    openInNewTab={true}
                    rel="nofollow"
                    href={leadURL as string}
                    productId={id.toString()}
                    affiliatePromoUrl={affiliatePromoUrl || undefined}
                    reason={`curated_ad-${collectionId}`}
                    beforeOnClick={onClick}
                >
                    {children}
                </LeadLink>
            </div>
        </div>
    );
}
