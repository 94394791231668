import clsx from "clsx";
import React, { useRef } from "react";
import { useIntersectionObserver } from "web/react/hooks/use-intersection-observer/use-intersection-observer";
import styles from "./lazy-load-image.module.css";

const DEFAULT_INTERSECTION_OBSERVER_OPTIONS = {
    threshold: 0,
    rootMargin: "0px",
    once: true,
};

export const placeholderImage =
    "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7";

interface LazyLoadImageProps {
    src: string;
    srcSet?: string;
    width?: number | string;
    height?: number | string;
    alt?: string;
    className?: string;
    role?: string;
    customTag?: string;
    placeholder?: string;
    intersectOpts?: {
        threshold?: number;
        rootMargin?: string;
        once?: boolean;
    };
}

export function LazyLoadImage({
    src,
    srcSet,
    width,
    height,
    alt,
    className,
    role,
    customTag,
    placeholder,
    intersectOpts,
}: LazyLoadImageProps): JSX.Element {
    const intersectionObserverOptions = {
        ...DEFAULT_INTERSECTION_OBSERVER_OPTIONS,
        ...(intersectOpts ? intersectOpts : {}),
    };
    const imageRef = useRef<HTMLImageElement | null>(null);
    const [isVisible] = useIntersectionObserver(intersectionObserverOptions, imageRef as any);

    function renderImage(): JSX.Element {
        return (
            <img
                className={clsx(className, styles.lazyLoadImage, {
                    [styles.loaded]: isVisible,
                })}
                ref={imageRef}
                src={isVisible ? src : placeholder || placeholderImage}
                srcSet={isVisible ? srcSet : undefined}
                width={width}
                height={height}
                alt={alt}
                role={role}
            />
        );
    }

    function renderCustomTag(): JSX.Element {
        if (customTag) {
            return React.createElement(customTag, {
                ref: imageRef,
                className,
                style: { backgroundImage: `url(${isVisible ? src : ""})` },
            });
        }

        return renderImage();
    }

    return !customTag ? renderImage() : renderCustomTag();
}

export default LazyLoadImage;
