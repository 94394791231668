import React from "react";
import { useDispatch, useSelector } from "react-redux";
import BranchLink from "web/react/components/branch-link/branch-link";
import GenericStickyModal from "web/react/components/generic-sticky-modal/generic-sticky-modal";
import { Heading } from "web/react/emo/heading";
import { Text } from "web/react/emo/text";
import { VStack } from "web/react/emo/v-stack";
import { View } from "web/react/emo/view";
import withReduxProvider from "web/react/redux-provider";
import { closeCustomerCaptureOverlay } from "web/redux/ducks/customer-capture-overlay";
import { ReduxStoreState } from "web/redux/store";
import { gettext } from "web/script/modules/django-i18n";
import { RawBranchLink } from "web/script/utils/branch-io";
import mySizesAdoptionOverlayImage from "./img/my-sizes-adoption.png";
import * as styles from "./my-sizes-adoption-overlay.css";

export function _MySizesAdoptionOverlay(): JSX.Element {
    const dispatch = useDispatch();

    const { open: overlayOpen } = useSelector(
        (state: ReduxStoreState) => state.customerCaptureOverlay
    );

    function triggerClose(): void {
        dispatch(closeCustomerCaptureOverlay());
    }

    return (
        <GenericStickyModal isOpen={overlayOpen} className={styles.modal} onClose={triggerClose}>
            <View padding="xs" height="full">
                <VStack align="center" justify="space-between" spacing="md">
                    <VStack spacing="xxs" align="center" justify="center">
                        <Heading textStyle="title-2">
                            {/* See only good fits */}
                            {gettext("my_sizes.onboarding.modal.title")}
                        </Heading>
                        <Text className={styles.description} textStyle="body-3" color="secondary">
                            {/* Shop the very best of fashion, exclusively in your size */}
                            {gettext("my_sizes.onboarding.modal.description")}
                        </Text>
                    </VStack>
                    <img
                        src={mySizesAdoptionOverlayImage}
                        alt="My Sizes onboarding image for the Lyst app"
                        className={styles.image}
                    />
                    <BranchLink
                        title={
                            // Download the Lyst App for free
                            gettext("my_sizes.onboarding.modal.download_button")
                        }
                        branchLink={RawBranchLink.MySizesOnboarding}
                        eventLabel="select_your_size"
                        primaryButton
                    ></BranchLink>
                </VStack>
            </View>
        </GenericStickyModal>
    );
}

export const MySizesAdoptionOverlay = withReduxProvider(_MySizesAdoptionOverlay);
