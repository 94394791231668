import { clsx } from "clsx";
import React, { useEffect, useState } from "react";
import LeadLink from "web/react/components/lead-link/lead-link";
import Select from "web/react/components/select/select";
import { Option } from "web/react/components/select/types";
import { SizeOption } from "web/react/components/size-picker/size-option";
import {
    ProductPurchaseTypes,
    useInStockProductPageContext,
} from "web/react/pages/product/in-stock/in-stock-product-page/in-stock-product-page.context";
import analytics from "web/script/analytics/analytics";
import { gettext } from "web/script/modules/django-i18n";
import { Features } from "web/types/analytics";
import { Schemas } from "web/types/examples/schemas";
import { ProductScreenSize, BuyBuyBuyAreaSize as Size } from "web/types/serializers";
import {
    SelectableSchemaSizePickerOptionHeading,
    SizePickerOptionHeading,
    SizePickerOptionHeadingProps,
} from "./option-heading";
import { centred, optionText, row } from "./size-option.css";
import * as styles from "./size-picker.css";

export function compileSizeOptions(sizes: Size[] | ProductScreenSize[]): Option[] {
    return sizes.map((size) => {
        const inStock = size.in_stock === undefined ? true : size.in_stock;
        return {
            selectedText: size.human_size || size.display_size,
            text: [size.human_size || size.display_size],
            value: size,
            disabled: !inStock,
        };
    });
}

function sizePickerFooter(): JSX.Element {
    const sizePickerDisclaimerLabel = gettext("product.buy_area.size_picker.supply_label");
    return (
        <div>
            <div className={styles.divider}></div>
            <div className={styles.disclaimer}>{sizePickerDisclaimerLabel}</div>
        </div>
    );
}

function expressCheckoutFooter(
    productId: string,
    leadUrl: string,
    afterOnClick: (expanded: boolean, shouldSendAnalytic: boolean) => void
): JSX.Element {
    return (
        <LeadLink
            href={leadUrl}
            productId={productId}
            openInNewTab={true}
            reason="click-to-see-more-sizes"
            afterOnClick={() => {
                afterOnClick(true, false);
            }}
            className={clsx(row, centred, optionText)}
        >
            {/* Click to see more sizes */}
            {gettext("product.buy_area.see_more_sizes")}
        </LeadLink>
    );
}

export function compileLocalizedSizeOptions(
    sizes: Size[] | ProductScreenSize[],
    schema: string
): Option[] {
    // Sometimes there are international conversions for some sizes and not others
    // This will allow the most schemas to be shown and will filter out the sizes not
    // converted into that schema.
    // These size pickers containing localised sizes are branded: New Taxonomy
    const validSizes = (sizes as any).filter((size: Size | ProductScreenSize) => {
        if (size.localized_sizes?.[schema]) {
            return size;
        }
    });
    return validSizes.map((size: Size | ProductScreenSize) => {
        const localizedSizeText = size.localized_sizes?.[schema].display_size as string;
        const retailerSizeText = size.retailer_size?.display_size;
        const inStock = size.in_stock === undefined ? true : size.in_stock;
        const selectedText =
            localizedSizeText === retailerSizeText
                ? localizedSizeText
                : `${localizedSizeText} / ${retailerSizeText}`;

        return {
            selectedText,
            text: [localizedSizeText, retailerSizeText],
            value: size,
            disabled: !inStock,
        } as Option;
    });
}

function createSelectableSchemaSizePickerOptionHeading(
    onSchemaSelect,
    schemas,
    selectedSchema
): (SizePickerOptionHeadingProps: SizePickerOptionHeadingProps) => JSX.Element {
    return function SelectableSchemaSizePickerOptionHeadingWrapper({
        onCloseButtonClick,
    }: SizePickerOptionHeadingProps): JSX.Element {
        return (
            <SelectableSchemaSizePickerOptionHeading
                onCloseButtonClick={onCloseButtonClick}
                onSchemaSelect={onSchemaSelect}
                schemas={schemas}
                selectedSchema={selectedSchema}
            />
        );
    };
}

export interface SizePickerProps {
    isExpanded: boolean;
    onSelect: (option: Option, isComparison: boolean) => void;
    schemas: Schemas;
    setExpanded: (expanded: boolean, shouldSendAnalytic?: boolean) => void;
    sizes: Size[] | ProductScreenSize[];
    userDefaultSchema: string;
    isComparison?: boolean;
    defaultSelectText?: string;
    error?: boolean;
    productType: ProductPurchaseTypes;
    showDisabledOptions?: boolean;
    selectedOption?: ProductScreenSize;
    isExpressCheckout?: boolean;
}

export function SizePicker({
    isExpanded,
    onSelect,
    schemas,
    setExpanded,
    sizes,
    userDefaultSchema,
    isComparison,
    defaultSelectText = "Select a size",
    error = false,
    productType,
    showDisabledOptions = false,
    selectedOption,
    isExpressCheckout = false,
}: SizePickerProps): JSX.Element {
    // TODO: i18n
    const [selectedSchema, setSelectedSchema] = useState(userDefaultSchema);

    const {
        product,
        activeBuyOption: { buyOption: activeBuyOption },
    } = useInStockProductPageContext();

    // Note (sizes as any) is used to ignore the "error TS2349: This expression is not callable." TS error
    // This is because the type of sizes is Size[] | ProductScreenSize[] which are not compatible with each other
    // Remove this with the PDP cleanup ticket
    const localizedSizes = (sizes as any).filter((size: Size | ProductScreenSize) => {
        return size.localized_sizes;
    });

    let hasLocalizedSizes = !!selectedSchema && localizedSizes.length > 0;

    const ANALYTICS_CATEGORY = isComparison ? "compare_prices_size_picker" : "size_picker";

    function getSizeOptions(): Option[] {
        if (hasLocalizedSizes) {
            const localizedSizeOptions = compileLocalizedSizeOptions(
                localizedSizes as Size[] | ProductScreenSize[],
                selectedSchema
            );

            // Make sure at least one option is available
            const areOptionsAvailable = localizedSizeOptions.some((option: Option) => {
                return !option.disabled;
            });
            if (areOptionsAvailable) return localizedSizeOptions;
        }

        hasLocalizedSizes = false;
        return compileSizeOptions(sizes);
    }

    const [sizeOptions, setSizeOptions] = useState<Option[]>(getSizeOptions());
    const [pickedOption, setPickedOption] = useState<Option | undefined>(undefined);

    useEffect(() => {
        if (!isComparison && !isExpressCheckout) {
            analytics.event(ANALYTICS_CATEGORY, "shown");
        }
    }, []);

    useEffect(() => {
        setSizeOptions(getSizeOptions());
    }, [selectedSchema]);

    useEffect(() => {
        if (selectedOption) {
            let selectedSizeOption: Option | undefined;
            if (hasLocalizedSizes) {
                selectedSizeOption = compileLocalizedSizeOptions(
                    [selectedOption],
                    selectedSchema
                )[0];
            } else {
                selectedSizeOption = compileSizeOptions([selectedOption])[0];
            }

            setPickedOption(selectedSizeOption);
        }
    }, [selectedOption, hasLocalizedSizes, selectedSchema, sizes]);

    function getFeatures(): Features {
        const features = {
            selectable_schema: hasLocalizedSizes,
        };
        return features;
    }

    function handleFirstExpand(): void {
        if (isExpressCheckout) {
            return;
        }

        const action = "first_expansion";
        const subtype = `checkout.${ANALYTICS_CATEGORY}.${action}`;

        // Trigger an analytic to send:
        // - Num Sizes
        // - Which schema is initially selected
        // - What features the size picker contains
        analytics.event(ANALYTICS_CATEGORY, action, "", false, null, subtype, {
            size_picker: {
                first_expansion: {
                    current_schema: selectedSchema,
                    features: getFeatures(),
                    sizes_available: localizedSizes.length || sizes.length,
                },
            },
        });
    }

    function handleSchemaSelect(event): void {
        const toSchema = event.target.value;
        const fromSchema = selectedSchema;

        setSelectedSchema(toSchema);

        const category = "size_picker";
        const action = "schema_change";
        const subType = `${productType}.${category}.${action}`;

        // Track which schema the user was on, and what they switched to
        analytics.event(category, action, undefined, false, null, subType, {
            size_picker: {
                schema_change: {
                    from_schema: fromSchema,
                    to_schema: toSchema,
                },
            },
        });
    }

    function onTouchOverlay(): void {
        setExpanded(false);
    }

    return (
        <>
            {isExpanded && <div className={styles.overlay} onTouchStart={onTouchOverlay}></div>}
            <Select
                dataTestId="size-picker-select"
                defaultSelectText={defaultSelectText}
                error={error}
                isExpanded={isExpanded}
                onFirstExpand={handleFirstExpand}
                onSelect={(option) => onSelect(option, !!isComparison)}
                onDisabledSelect={(option) => onSelect(option, !!isComparison)}
                OptionComponent={SizeOption}
                OptionHeadingComponent={
                    hasLocalizedSizes
                        ? createSelectableSchemaSizePickerOptionHeading(
                              handleSchemaSelect,
                              schemas,
                              selectedSchema
                          )
                        : SizePickerOptionHeading
                }
                OptionFooterComponent={
                    isExpressCheckout
                        ? expressCheckoutFooter(
                              product.product_id,
                              activeBuyOption.affiliate_url,
                              setExpanded
                          )
                        : sizePickerFooter()
                }
                options={sizeOptions}
                setExpanded={setExpanded}
                showOnlyOptionByDefault={true}
                showDisabledOptions={showDisabledOptions}
                pickedOption={pickedOption}
            />
        </>
    );
}
