import clsx from "clsx";
import React, { RefObject } from "react";
import BrandAdLoader from "web/react/components/brand-ad-loader/brand-ad-loader";
import PrimaryButton from "web/react/components/buttons/primary-button/primary-button";
import { isProfileHeader } from "web/react/components/feed-header/feed-header";
import FollowDesignerButton from "web/react/components/follow-designer-button/LEGACY/follow-designer-button";
import SVGIcon from "web/react/components/svg-icon/svg-icon";
import { gettext } from "web/script/modules/django-i18n";
import { languageIsEnglish } from "web/script/modules/language";
import {
    FeedHeaderSerializer,
    ProfileHeaderSerializer,
    ShopHeaderSerializer,
} from "web/types/serializers";
import styles from "./feed-header.module.css";

interface FeedHeaderWithLogoProps {
    title: string;
    subtitle?: string | JSX.Element;
    profile?: ProfileHeaderSerializer | ShopHeaderSerializer | FeedHeaderSerializer;
    followCTARef?: RefObject<HTMLDivElement>;
    actions?: JSX.Element;
}
export function FeedHeaderWithLogo({
    profile,
    followCTARef,
    title,
    subtitle,
    actions,
}: FeedHeaderWithLogoProps): JSX.Element {
    return (
        <>
            <div
                className={clsx(styles.wrapper, {
                    [styles.hasAdvert]: !!profile?.brand_ad,
                })}
            >
                <div className={styles.content}>
                    <div ref={followCTARef} className={clsx(styles.start)}>
                        {profile && isProfileHeader(profile) && profile.small_photo_url && (
                            <div className={styles.icon}>
                                <img
                                    src={profile.small_photo_url}
                                    alt={
                                        // "{designer_name} logotype"
                                        gettext("feed.designer_logo.image_alt_text", {
                                            designer_name: profile.name,
                                        })
                                    }
                                    width={48}
                                    height={48}
                                />
                            </div>
                        )}
                        <h1 className={styles.title}>
                            {title}
                            {subtitle}
                        </h1>
                    </div>
                    {actions ? <div className={styles.end}>{actions}</div> : null}
                    {profile && isProfileHeader(profile) && (
                        <div className={styles.buttonWrapper}>
                            {languageIsEnglish() && profile.follow_button && (
                                <div className={styles.followButtonWrapper}>
                                    <FollowDesignerButton
                                        className={styles.followButton}
                                        followedText={
                                            // "Following"
                                            gettext("follow_button.label.following")
                                        }
                                        notFollowedText={
                                            // "Follow"
                                            gettext("follow_button.label.follow")
                                        }
                                        analyticsParameters={[
                                            "du_feed_cta",
                                            "click_top_cta",
                                            "du_cta",
                                        ]}
                                        lystId={profile.follow_button.lyst_id}
                                        isAuthenticated={profile.follow_button.is_authenticated}
                                        isFollowing={profile.follow_button.is_following as string}
                                        designerName={title}
                                    />
                                </div>
                            )}
                            {languageIsEnglish() && profile.retailer_hub_link && (
                                <PrimaryButton
                                    className={styles.promos}
                                    href={profile.retailer_hub_link}
                                    disabled={false}
                                    fullWidth={false}
                                    fullHeight={false}
                                    openInNewTab={false}
                                >
                                    <SVGIcon className={styles.promosIcon} name={"tag"} />
                                    {
                                        // "Promo’s"
                                        gettext("promotion_message.designer.promo_btn_text")
                                    }
                                </PrimaryButton>
                            )}
                        </div>
                    )}
                </div>
            </div>
            {profile?.brand_ad && (
                <div className={styles.brandAd}>
                    <BrandAdLoader {...profile?.brand_ad} />
                </div>
            )}
        </>
    );
}
