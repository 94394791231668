import React from "react";
import { JsonLd } from "react-schemaorg";
import { BreadcrumbList } from "schema-dts";
import analytics from "web/script/analytics/analytics";
import { BreadcrumbsSerializer } from "web/types/serializers";
import * as styles from "./breadcrumbs.css";

const BREADCRUMB_TYPES = {
    feed: "feed",
    pdp: "pdp",
};

interface BreadCrumbsProps extends BreadcrumbsSerializer {
    type: keyof typeof BREADCRUMB_TYPES;
}

export function Breadcrumbs({ breadcrumbs, type }: BreadCrumbsProps): React.ReactElement {
    function handleBreadcrumbClick(): void {
        const action = type === BREADCRUMB_TYPES.pdp ? "pdp_related_click" : "feed_click";
        analytics.event(`${type}_breadcrumb`, action);
    }

    return (
        <div data-testid="breadcrumbs">
            <ol className={styles.breadcrumbs}>
                {breadcrumbs.map((breadcrumb) => (
                    <li key={breadcrumb.name} className={styles.breadcrumb}>
                        {breadcrumb.url ? (
                            <a
                                href={breadcrumb.url}
                                onClick={handleBreadcrumbClick}
                                className={styles.link}
                            >
                                <span className={styles.name}>{breadcrumb.name}</span>
                            </a>
                        ) : (
                            <span className={styles.name}>{breadcrumb.name}</span>
                        )}
                    </li>
                ))}
            </ol>
            <JsonLd<BreadcrumbList>
                item={{
                    "@context": "https://schema.org",
                    "@type": "BreadcrumbList",
                    itemListElement: breadcrumbs.map((breadcrumb, i) => ({
                        "@type": "ListItem",
                        position: i + 1,
                        item: {
                            "@id": breadcrumb.url,
                            name: breadcrumb.name,
                        },
                    })),
                }}
            />
        </div>
    );
}
