import googleConversion from "web/script/embedded/google-conversion";

export default {
    // Regions where we apply Google Consent Mode for our Google Analytics
    // EEA includes EU countries and Switzerland (CH)
    EEA: [
        "AT",
        "BE",
        "BG",
        "HR",
        "CY",
        "CZ",
        "DK",
        "EE",
        "FI",
        "FR",
        "DE",
        "GR",
        "HU",
        "IS",
        "IE",
        "IT",
        "LV",
        "LI",
        "LT",
        "LU",
        "MT",
        "NL",
        "NO",
        "PL",
        "PT",
        "RO",
        "SK",
        "SI",
        "ES",
        "SE",
        "CH",
    ],

    UK: ["GB"],

    USA: ["US"],

    setDefaultConsentMode: function () {
        // Set GCM default state
        // EEA + UK Region
        googleConversion("consent", "default", {
            ad_storage: "denied",
            analytics_storage: "denied",
            // gcm v2 parameters
            ad_user_data: "denied",
            ad_personalization: "denied",
            region: this.EEA.concat(this.UK),
        });
        // USA Region
        googleConversion("consent", "default", {
            ad_storage: "granted",
            analytics_storage: "granted",
            // gcm v2 parameters
            ad_user_data: "granted",
            ad_personalization: "granted",
            region: this.USA,
        });
    },

    updateConsentMode: function (cookiesState) {
        // Update Consent Mode
        var adStorageCookie = cookiesState.acceptsAds ? "granted" : "denied";
        var analyticsStorageCookie = cookiesState.acceptsAnalytics ? "granted" : "denied";

        googleConversion("consent", "update", {
            ad_storage: adStorageCookie,
            analytics_storage: analyticsStorageCookie,
            // gcm v2 parameters
            ad_user_data: adStorageCookie,
            ad_personalization: adStorageCookie,
        });
    },
};
