import clsx from "clsx";
import React from "react";
import LeadLink from "web/react/components/lead-link/lead-link";
import { Text } from "web/react/emo/text";
import { View } from "web/react/emo/view";
import { useDomViewport } from "web/react/hooks/use-dom-viewport/use-dom-viewport";
import { gettext } from "web/script/modules/django-i18n";
import { ProductCardSerializer } from "web/types/serializers";
import * as styles from "./product-gallery-more-products.css";

interface MoreProductsProps {
    products: ProductCardSerializer[];
    fullscreen?: boolean;
}

interface ProductProps {
    product: ProductCardSerializer;
}

function Product({ product }: ProductProps): React.ReactElement {
    const content = (
        <div>
            <img className={styles.product} src={product.image_url || ""} />
        </div>
    );

    return (
        <div className={styles.card}>
            {product.lead_enabled && product.lead_url ? (
                <LeadLink
                    reason="image-gallery-related"
                    href={product.lead_url}
                    openInNewTab={true}
                >
                    {content}
                </LeadLink>
            ) : (
                <a href={product.url}>{content}</a>
            )}
        </div>
    );
}

export function MoreProducts({
    products,
    fullscreen = false,
}: MoreProductsProps): React.ReactElement {
    const { isMobileViewport, isTabletViewport } = useDomViewport();
    const renderedProducts = products.map((product) => {
        return <Product key={product.image_url as string} product={product} />;
    });
    const viewSize = isMobileViewport ? "sm" : isTabletViewport ? "md" : "lg";

    const wrapperClasses = clsx(styles.wrapper[viewSize], {
        [styles.fullscreenWrapper]: fullscreen,
    });

    return (
        <div className={wrapperClasses}>
            <div className={styles.container}>
                <View paddingY="xs" className={styles.textContainer}>
                    <Text textStyle={"body-2"}>
                        {gettext("product.product_more_details_try_instead")}
                    </Text>
                </View>
                <div className={styles.products}>{renderedProducts}</div>
            </div>
        </div>
    );
}
