import _map from "lodash/map";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import ErrorModal from "web/react/components/checkout/error-modal/error-modal";
import { KevelCampaignControl } from "web/react/components/kevel-campaign-control/kevel-campaign-control";
import { Button } from "web/react/emo/button";
import { useDomViewport } from "web/react/hooks/use-dom-viewport/use-dom-viewport";
import { useLeadLinkClick } from "web/react/hooks/use-lead-link-click/use-lead-link-click";
import {
    PurchaseIntentCheckoutType,
    PurchaseIntentPurchaseType,
} from "web/react/pages/checkout/checkout.context";
import { useInStockProductPageContext } from "web/react/pages/product/in-stock/in-stock-product-page/in-stock-product-page.context";
import { isSelectedSize } from "web/react/pages/product/in-stock/in-stock-product-page/utils";
import withReduxProvider from "web/react/redux-provider";
import { openCustomerCaptureOverlay } from "web/redux/ducks/customer-capture-overlay";
import analytics from "web/script/analytics/analytics";
import { gettext } from "web/script/modules/django-i18n";
import environment from "web/script/modules/environment";
import requester from "web/script/modules/requester";
import userProfiler from "web/script/modules/userprofiler";
import { canUseMembership } from "web/script/modules/utils";
import { removeCurrencyChars } from "web/script/utils/currency-symbols";
import uuid4 from "web/script/utils/uuid";
import { ProductBuyOptionsSerializer } from "web/types/serializers";

const OLD_CHECKOUT_ENDPOINT = "/cart/one-click-checkout/";
const NATIVE_CHECKOUT_ENDPOINT = "/checkout/now/";

enum BuyOptionsRequestErrorCode {
    GENERIC_ERROR = "generic_error",
    CHECKOUT_SIZE_NOT_IN_STOCK = "CheckoutSizeNotInStock",
}

type ErrorModalProperties = {
    title: string;
    desc: string;
    ctaText: string;
    open: boolean;
};

/**
 * @typedef ConditionalBuyButtonProps
 * @description shared type used to infer props depending on the action
 */
type ConditionalBuyButtonProps =
    | {
          action: "buy";
          openModal?: never;
      }
    | {
          action: "openModal";
          openModal: () => void;
      };

type CommonBuyButtonProps = {
    variant: "checkout" | "affiliate";
    buyOption: ProductBuyOptionsSerializer;
    buyOptionPosition: number;
    analyticsFunctionEventLabel?: string;
    isComparison?: boolean;
    secondary?: boolean;
    variantContext?: string;
    sendExtraAnalytics?: boolean;
};

type BuyButtonProps = CommonBuyButtonProps & ConditionalBuyButtonProps;

function _BuyButton({
    variant,
    action,
    buyOption,
    buyOptionPosition,
    openModal,
    analyticsFunctionEventLabel,
    isComparison = false,
    secondary = false,
    variantContext,
    sendExtraAnalytics = true,
}: BuyButtonProps): React.ReactElement {
    const {
        link_id: linkId,
        retailer_slug: retailerSlug,
        retailer_name: retailerName,
        price,
        promotions,
        checkout_flow: checkoutFlow,
        free_shipping: hasFreeShipping,
        shipping_cost: shippingCostLabel,
    } = buyOption;
    const {
        product,
        activeProduct,
        sizePicker,
        comparison: { closeCompareModal },
        checkoutModal: { setModalBuyOption },
    } = useInStockProductPageContext();

    const { isDesktopViewport } = useDomViewport();
    const isNativeCheckout = checkoutFlow === "native";
    const hasPromotion = promotions.length > 0;
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [errorModalProperties, setErrorModalProperties] = useState<ErrorModalProperties>({
        title: "",
        desc: "",
        ctaText: "",
        open: false,
    });
    const dispatch = useDispatch();

    function generateErrorModalText(errorCode: string): Partial<ErrorModalProperties> {
        switch (errorCode) {
            case BuyOptionsRequestErrorCode.CHECKOUT_SIZE_NOT_IN_STOCK:
                return {
                    title: gettext("error.oos_modal.title"), // This product is out of stock
                    desc: gettext("error.oos_modal.description"), // We're sorry, but this product is currently out of stock. We can send you a notification when it's back.
                    ctaText: gettext("general.close"), // Close
                };
            case BuyOptionsRequestErrorCode.GENERIC_ERROR:
            default:
                return {
                    title: gettext("error.general_modal.title"), // Something's gone wrong
                    desc: gettext("error.general_modal.description"), // We're sorry, but it looks like something's gone wrong. Please try again later.
                    ctaText: gettext("general.close"), // Close
                };
        }
    }

    async function sendBuyActionAnalytics(
        buyOption: Partial<ProductBuyOptionsSerializer>
    ): Promise<any[]> {
        const label = product.is_saved_for_later
            ? "save_for_later_add_to_cart"
            : "add_to_cart_button";

        const analyticsPromises = [
            analytics.ecommerce("ec:addProduct", {
                name: environment.get("analyticsProduct.product_canonical_slug"),
                category: environment.get("analyticsProduct.ga_category"),
                brand: environment.get("analyticsProduct.designer_slug"),
                variant: environment.get("analyticsProduct.product_uid"),
                price: buyOption.price,
                quantity: 1,
                dimension20: environment.get("analyticsProduct.gender"),
                dimension29: environment.get("analyticsProduct.color"),
                dimension28: true,
                dimension30: false,
                dimension32: "Lyst",
                dimension34: activeProduct.selectedSizeOption?.raw_size,
                dimension23: buyOption.retailer_slug,
                metric8: buyOption.applied_discount,
                dimension31: activeProduct.selectedSizeOption?.is_final_sale,
                dimension27: buyOption.free_shipping,
            }),
            analytics.ecommerce("ec:setAction", "add"),
            analytics.event("cart", "add_item", label, false, {
                product_id: product.product_id,
                product_name: environment.get("analyticsProduct.product_canonical_slug"),
                price: buyOption.price,
                currency: environment.get("currencyProps.currencyCode"),
            }),
        ];
        return Promise.all(analyticsPromises);
    }

    function normalizeShippingCost(freeShipping: boolean, shippingCost: string | null): number {
        return !freeShipping && shippingCost ? removeCurrencyChars(shippingCost) : 0;
    }

    function sendCtaAnalytic({ cta }): void {
        if (!sendExtraAnalytics) {
            return;
        }

        const category = "buy_area";
        const action = "buy_options";
        const subType = `checkout.${category}.${action}`;

        analytics.event(category, action, "", false, null, subType, {
            buy_area: {
                buy_cta: {
                    cta,
                    option: {
                        retailer: retailerSlug,
                        price: price,
                        shipping: normalizeShippingCost(!!hasFreeShipping, shippingCostLabel),
                        is_checkout: !!(variant === "checkout"),
                        has_promotion: hasPromotion,
                        link_id: linkId,
                        position: buyOptionPosition, // position at which the buy option is displayed to the user
                    },
                },
            },
        });
    }

    function sendCheckoutAnalytics(
        purchaseIntentSessionId: string,
        checkoutType: PurchaseIntentCheckoutType
    ): void {
        if (!sendExtraAnalytics) {
            return;
        }

        const category = "purchase_pdp";
        const action = "clicked";
        const subType = `${category}.${action}`;

        analytics.event(category, action, "", false, null, subType, {}, [
            {
                item_type: "buy_option",
                id: linkId,
                sizes: [
                    {
                        size: activeProduct.selectedSizeOption?.raw_size,
                        on_sale: activeProduct,
                        price: activeProduct.price.fullPrice,
                        current_price:
                            activeProduct.price.salePrice || activeProduct.price.fullPrice,
                        currency: environment.get("currencyProps.currencyCode"),
                    },
                ],
                retailer: {
                    slug: retailerSlug,
                    retailer_integration_type: buyOption.retailer_integration_type,
                },
                product: {
                    id: product.product_id,
                    slug: product.canonical_slug,
                    category: product.designer_category_url,
                },
            },
            {
                item_type: "purchase_intent_session",
                purchase_intent_session_id: purchaseIntentSessionId,
                purchase_type: PurchaseIntentPurchaseType.CHECKOUT,
                checkout_type: checkoutType,
            },
        ]);
    }

    async function callEndpoint(
        endpoint: string,
        buyOption: ProductBuyOptionsSerializer,
        sessionId: string | null,
        onSuccess: (response: Record<string, any>) => boolean,
        onError: (error: Error) => any
    ): Promise<Response> {
        sendBuyActionAnalytics(buyOption);
        const rawSize = buyOption.sizes.filter(
            (size) =>
                activeProduct.selectedSizeOption &&
                isSelectedSize(activeProduct.selectedSizeOption, size)
        );

        const requestData = {
            method: "POST",
            body: {
                buy_option: JSON.stringify({
                    icon: true, // TODO: remove when old checkout is removed
                    link_id: String(buyOption.link_id),
                    raw_size: rawSize[0].raw_size,
                    purchase_intent_session_id: sessionId,
                }),
                is_base64_encoded: false,
                country: environment.get("country"),
            },
        };

        return requester(endpoint, requestData as any)
            .then(requester.toJSON)
            .then((response) => {
                if (!response.success) {
                    let error = new Error() as any;
                    error.response = response;
                    throw error;
                } else {
                    return onSuccess(response);
                }
            })
            .catch((err) => {
                return onError(err);
            });
    }

    async function onNativeCheckoutClick(
        buyOption: ProductBuyOptionsSerializer,
        purchaseIntentSessionId: string
    ): Promise<Response> {
        function onSuccess(response: any): boolean {
            window.location = response.data.url;
            return true;
        }

        function onError(error: any): void {
            const errorCode = error.response.error;

            analytics.event("purchase_pdp", "errored", errorCode, false, null);
            const { title, desc, ctaText } = generateErrorModalText(errorCode);
            setErrorModalProperties({
                title: title as string,
                desc: desc as string,
                ctaText: ctaText as string,
                open: true,
            });
        }

        return callEndpoint(
            NATIVE_CHECKOUT_ENDPOINT,
            buyOption,
            purchaseIntentSessionId,
            onSuccess,
            onError
        );
    }

    async function onOldCheckoutClick(
        buyOption: ProductBuyOptionsSerializer,
        purchaseIntentSessionId: string
    ): Promise<Response> {
        function onSuccess(response: any): boolean {
            window.location = response.data.url;
            return true;
        }

        function onError(err: any): any {
            analytics.event("CART", "ADD_TO_BAG_ERROR");
            let errorMessage = "Something went wrong!";

            const errorCode = err.response.error;

            analytics.event("purchase_pdp", "errored", errorCode, false, null);

            if (err.response) {
                if (err.response["data"]) {
                    errorMessage = _map(err.response["data"], "message").join(" ");
                }

                if (
                    errorMessage.indexOf("something went wrong trying to checkout this product") >
                    -1
                ) {
                    analytics.event("cart", "add_to_cart_error", "cart_add_item_error");
                } else if (
                    errorMessage.indexOf("This product seems to no longer be in stock") > -1
                ) {
                    analytics.event("cart", "add_to_cart_error", "product_no_longer_in_stock");
                } else if (errorMessage.indexOf("You need to select an option") > -1) {
                    analytics.event("cart", "add_to_cart_error", "no_buy_option_detected");
                } else if (errorMessage.indexOf("You need to select a size") > -1) {
                    analytics.event("cart", "add_to_cart_error", "no_size_detected");
                }
            }

            const { title, desc, ctaText } = generateErrorModalText(
                BuyOptionsRequestErrorCode.GENERIC_ERROR
            );
            setErrorModalProperties({
                title: title as string,
                desc: desc as string,
                ctaText: ctaText as string,
                open: true,
            });
            return err;
        }

        return callEndpoint(
            OLD_CHECKOUT_ENDPOINT,
            buyOption,
            purchaseIntentSessionId,
            onSuccess,
            onError
        );
    }
    function validateCheckout(): void {
        if (activeProduct.selectedSizeOption === null && action === "openModal") {
            openModal();
            setModalBuyOption(buyOption);
        }
        if (activeProduct.selectedSizeOption === null && sizePicker.showSizePicker === true) {
            if (isComparison && isDesktopViewport) {
                sizePicker.setComparisonError(true);
                return;
            } else {
                sizePicker.setError(true);
                closeCompareModal();
                return;
            }
        }

        if (action === "buy" && !sizePicker.error && !sizePicker.comparisonError) {
            initiateCheckout();
        }
    }

    async function initiateCheckout(): Promise<void> {
        setButtonDisabled(true);
        // Generate a checkout purchase intent session uuid
        // This is used to track checkout analytics
        const sessionId = uuid4.uuid4();

        sendCtaAnalytic({ cta: "buy now" });

        if (isNativeCheckout) {
            sendCheckoutAnalytics(sessionId, PurchaseIntentCheckoutType.NATIVE);
            await onNativeCheckoutClick(buyOption, sessionId);
        } else {
            sendCheckoutAnalytics(sessionId, PurchaseIntentCheckoutType.OLD_CHECKOUT);
            await onOldCheckoutClick(buyOption, sessionId);
        }

        setButtonDisabled(false);
    }

    /**
     * @summary Affiliate buy option functions
     * */

    function fireAnalyticsEvent(): void {
        analytics.event(
            "buy_area",
            "clicked",
            analyticsFunctionEventLabel || "buy-on-store-button"
        );
    }

    function afterOnClick(extraContent): void {
        if (!userProfiler.isLoggedIn() && canUseMembership()) {
            dispatch(
                openCustomerCaptureOverlay({
                    productId: product.product_id,
                    captureType: "signup_lead_save",
                    analyticsEventLabel: isComparison ? "comparison_option" : "is-product-shop-now",
                    ...extraContent,
                })
            );
        }
    }

    const { onClick: onLeadLinkClick, leadHref } = useLeadLinkClick({
        href: buyOption.affiliate_url,
        productId: product.product_id,
        reason: isComparison ? "buy-on-store-alternative-option" : "buy-on-store-button",
        openInNewTab: true,
    });

    // ENRICH-3347: Change the `Buy now` to `Buy from <retailerName>`
    const enableBuyFromLabel = environment.getFeature("act_buy_from_retailer_text");
    const buttonTitle =
        variantContext === "express_checkout"
            ? // Buy direct
              gettext("product.buy_area.buy_direct_btn_text")
            : enableBuyFromLabel && variant === "affiliate"
            ? // Buy from `retailerName`
              // TODO: i18n
              `Buy from ${retailerName}`
            : // Buy now
              gettext("product.buy_area.buy_now");

    return variant === "checkout" ? (
        <>
            <ErrorModal
                title={errorModalProperties.title}
                description={errorModalProperties.desc}
                isOpen={errorModalProperties.open}
                onClose={() => setErrorModalProperties({ ...errorModalProperties, open: false })}
                buttonCTAText={errorModalProperties.ctaText}
                buttonCTA={() => setErrorModalProperties({ ...errorModalProperties, open: false })}
            />
            <Button
                title={buttonTitle}
                width="full"
                onClick={validateCheckout}
                disabled={buttonDisabled}
            />
        </>
    ) : (
        <KevelCampaignControl
            contextType={variantContext || isComparison ? "comparison_option" : "shop_now"}
            options={{
                action: "shop_now_button_click",
                hasPromotion,
                isAffiliate: true,
                retailerSlug,
            }}
            defaultAction={(event) => onLeadLinkClick(event)}
            analyticsEventLabel={isComparison ? "comparison_option" : "is-product-shop-now"}
            beforeOnClick={() => {
                fireAnalyticsEvent();
            }}
            afterOnClick={afterOnClick}
            productImageUrl={activeProduct.productImageUrl}
            continueOptionHref={leadHref}
        >
            <a data-testid="buy-button" href={leadHref} target={"_blank"} rel="noreferrer">
                <Button
                    variant={secondary ? "secondary" : "primary"}
                    title={buttonTitle}
                    width="full"
                />
            </a>
        </KevelCampaignControl>
    );
}

export const BuyButton = withReduxProvider(_BuyButton);
