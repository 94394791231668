import pinterest from "web/script/embedded/pinterest";

export default {
    send: function (data) {
        data.forEach((event) => {
            // Send Page View events on all pages apart from Lead page
            if (event.data.page_type !== "lead" && event.type === "page_view") {
                pinterest();
            }
        });

        return Promise.resolve();
    },
};
