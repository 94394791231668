/**
 * Defines TikTok tag event names.
 * See https://ads.tiktok.com/help/article/standard-events-parameters?lang=en for a list of events
 */
export default {
    EVENT_NAMES: ["AddToCart", "CompleteRegistration", "PageView", "ViewContent"],

    affiliate: {
        action: "lead",
        name: "AddToCart",
    },
    cart: {
        action: "add_item",
        label: "add_to_cart_button",
        name: "AddToCart",
    },
    page_view: {
        name: "PageView",
    },
    signup: {
        // Signup event using email
        action: "click",
        label: "signup_button",
        name: "CompleteRegistration",
    },
    signup_header: {
        // Signup event using social (Google, Apple, Facebook)
        action: "click_social_sign_up",
        name: "CompleteRegistration",
    },
    view_content: {
        name: "ViewContent",
    },
};
