import clsx from "clsx";
import React from "react";
import { Heading } from "web/react/emo/heading";
import { Text } from "web/react/emo/text";
import { View } from "web/react/emo/view";
import { useDomViewport } from "web/react/hooks/use-dom-viewport/use-dom-viewport";
import styles from "./product-header.module.css";

interface ProductHeaderProps {
    variant?: "checkout" | "affiliate" | "emotional";
    isOwnerActive: boolean;
    isOwnerFeedLinkable: boolean;
    designerUrl: string;
    ownerName: string;
    shortDescription: string | null;
    isModal?: boolean;
}

function ProductHeader({
    isOwnerActive,
    isOwnerFeedLinkable,
    designerUrl,
    ownerName,
    shortDescription,
    variant = "emotional",
    isModal,
}: ProductHeaderProps): React.ReactElement {
    const { isDesktopViewport } = useDomViewport();
    if (variant === "emotional") {
        return (
            <Heading
                as={"h1"}
                textStyle={
                    isDesktopViewport ? "large-title-2" : isModal ? "title-3" : "large-title-2"
                }
            >
                <View marginBottom={"xs"} width={"full"}>
                    {isOwnerActive && isOwnerFeedLinkable ? (
                        <a className={styles.noUnderline} href={designerUrl}>
                            {ownerName}
                        </a>
                    ) : (
                        ownerName
                    )}
                </View>
                <Text
                    textStyle={isDesktopViewport ? "body-1" : isModal ? "body-3" : "body-1"}
                    lineLimit={2}
                >
                    {shortDescription}
                </Text>
            </Heading>
        );
    }

    return (
        <div
            className={clsx({
                [styles.checkout]: variant === "checkout",
                [styles.affiliate]: variant === "affiliate",
            })}
        >
            <h1 className={styles.title}>
                <div className={styles.designer}>
                    {isOwnerActive && isOwnerFeedLinkable ? (
                        <a href={designerUrl}>{ownerName}</a>
                    ) : (
                        ownerName
                    )}
                </div>

                {shortDescription && (
                    <div className={styles.shortDescription}>{shortDescription}</div>
                )}
            </h1>
        </div>
    );
}

export default ProductHeader;
