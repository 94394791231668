import PropTypes from "prop-types";
import React from "react";
import Expandable from "web/react/components/expandable/expandable";
import LeadLink from "web/react/components/lead-link/lead-link";
import analytics from "web/script/analytics/analytics";
import { gettext } from "web/script/modules/django-i18n";
import { ProductPropType } from "web/types/product";
import styles from "./product-description-details.module.css";

function ProductDescriptionDetails({ product, className }) {
    const productDescriptionContent = (
        <>
            {product.product_sizes && <p className={styles.noMarginTop}>{product.product_sizes}</p>}

            {product.stock_availability_txt && (
                <p className={styles.noMarginTop}>
                    {product.stock_availability_txt}
                    <LeadLink
                        href={product.lead_url}
                        openInNewTab={true}
                        productId={product.product_id}
                        reason="detail-retailer-size-link"
                    >
                        {product.retailer_name}
                    </LeadLink>
                </p>
            )}

            <p className={styles.noMarginTop}>{product.long_description}</p>

            {product.more_descriptions &&
                product.more_descriptions.descriptions &&
                product.more_descriptions.descriptions.map((productDescription) => (
                    <React.Fragment key={productDescription.from_retailer_name}>
                        <h4>{productDescription.from_retailer_name}</h4>
                        <p>{productDescription.short_description}</p>
                        <p>{productDescription.description}</p>
                    </React.Fragment>
                ))}
        </>
    );

    return (
        <div className={className}>
            <Expandable
                renderLabel={() => <h4>{gettext("in_stock_product.details")}</h4>}
                onClick={(isOpen) => {
                    if (!isOpen) {
                        analytics.event("product area", "click", "open-details");
                    }
                }}
            >
                <div>{productDescriptionContent}</div>
            </Expandable>
        </div>
    );
}

ProductDescriptionDetails.propTypes = {
    product: ProductPropType.isRequired,
    className: PropTypes.string,
};
export default ProductDescriptionDetails;
