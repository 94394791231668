import clsx from "clsx";
import React, { useRef, useState } from "react";
import { useNavigationContext } from "web/react/components/navigation/navigation.context";
import SVGIcon from "web/react/components/svg-icon/svg-icon";
import { Flyout } from "web/react/emo/flyout/flyout";
import { HStack } from "web/react/emo/h-stack";
import { ListItem } from "web/react/emo/list-item";
import { Text } from "web/react/emo/text";
import { AppPreHeaderBarLanguageSelectorSerializer } from "web/types/serializers";
import * as styles from "./language-selector.css";

interface LanguageSelectorProps {
    calloutText: AppPreHeaderBarLanguageSelectorSerializer["callout_text"];
    choices: AppPreHeaderBarLanguageSelectorSerializer["choices"];
}

export function LanguageSelector({
    calloutText,
    choices,
}: LanguageSelectorProps): React.ReactElement {
    const { showMenu, showSearch, handleHeaderAnalytics } = useNavigationContext();

    const [isOpen, setIsOpen] = useState<boolean>(false);

    const flyoutRef = useRef<HTMLDivElement>(null);

    return (
        <div data-testid="language-selector" ref={flyoutRef}>
            <button
                data-testid="language-selector-open"
                onClick={() => {
                    !isOpen && handleHeaderAnalytics("language_selector", "opened");
                    setIsOpen(!isOpen);
                }}
            >
                <Text textStyle="body-3">{calloutText}</Text>
            </button>
            <div
                className={clsx(styles.flyOutContent, {
                    [styles.hide]: !isOpen || showMenu || showSearch,
                })}
            >
                <Flyout parentRef={flyoutRef} isOpen={isOpen} handleClose={() => setIsOpen(false)}>
                    <ul>
                        {choices.map((choice) => (
                            <ListItem
                                key={choice.url}
                                as="a"
                                href={choice.url}
                                onClick={() => {
                                    handleHeaderAnalytics("language_selector", "clicked");
                                }}
                                data-testid={choice.language_name}
                            >
                                <HStack align="center" spacing="xxs">
                                    {choice.is_selected ? (
                                        <SVGIcon
                                            name="round-radiobutton-checked"
                                            className={clsx({
                                                [styles.radioIconSelected]: choice.is_selected,
                                            })}
                                        />
                                    ) : (
                                        <SVGIcon name="round-radiobutton" />
                                    )}
                                    <Text
                                        textStyle="body-3"
                                        color={choice.is_selected ? "secondaryBrand" : "primary"}
                                    >
                                        {choice.language_name}
                                    </Text>
                                </HStack>
                            </ListItem>
                        ))}
                    </ul>
                </Flyout>
            </div>
        </div>
    );
}
