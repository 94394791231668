import clsx from "clsx";
import React from "react";
import { useNavigationContext } from "web/react/components/navigation/navigation.context";
import SVGIcon from "web/react/components/svg-icon/svg-icon";
import { ListItem } from "web/react/emo/list-item/list-item";
import { Text } from "web/react/emo/text";
import { View } from "web/react/emo/view";
import * as styles from "./subcategory.css";

interface SubCategoryProps {
    title: string;
    submenu: any;
    onCategoryClick?: (menu: any) => void;
    onClose?: () => void;
    fullRender?: boolean;
    show?: boolean;
}

export function SubCategory({
    title,
    submenu,
    onCategoryClick,
    onClose,
    fullRender,
    show,
}: SubCategoryProps): React.ReactElement {
    const { handleCategoryAnalytics } = useNavigationContext();

    const hasLinks = submenu.links && submenu.links.length > 0;
    const hasTrendMenus = submenu.trend_menus && submenu.trend_menus.length > 0;

    function renderSubCategory(submenu, type): void {
        return submenu[type].map((menu) => (
            <SubCategory
                key={menu.display_name}
                title={menu.display_name}
                submenu={menu}
                fullRender={true}
            />
        ));
    }

    return (
        <>
            <div className={clsx(styles.menu, { [styles.hide]: !show })}>
                <View marginTop="sm" marginX="sm">
                    <button
                        type="button"
                        onClick={onClose}
                        className={clsx(styles.categoryTitleContainer)}
                    >
                        {!fullRender && <SVGIcon name="arrow-emo" className={styles.backArrow} />}
                        <Text textStyle="callout">{title}</Text>
                        {/* this empty view allows us to easily align the header */}
                        <View className={styles.titleHiddenPadding} />
                    </button>
                    <View className={styles.divider} marginBottom="xxxs" />
                </View>
                <View height="fit-content" marginTop="xxs" marginBottom="sm">
                    {hasLinks &&
                        submenu.links.map((link) => (
                            <ListItem
                                data-testid="link"
                                as="a"
                                key={link.url}
                                href={link.url}
                                showChevron
                                onClick={() => {
                                    handleCategoryAnalytics("product_category");
                                }}
                            >
                                <Text textStyle="body-3" color={link.is_sale ? "error" : "primary"}>
                                    {link.text}
                                </Text>
                            </ListItem>
                        ))}
                    {hasTrendMenus && (
                        <>
                            <View className={styles.divider} marginX="sm" marginTop="sm" />
                            {submenu.trend_menus.length === 1 ? (
                                <>
                                    <View margin="sm">
                                        <Text textStyle="callout">
                                            {submenu.trend_menus[0].display_name}
                                        </Text>
                                    </View>

                                    {submenu.trend_menus[0].links.map((link) => (
                                        <ListItem
                                            data-testid="link"
                                            as="a"
                                            key={link.text}
                                            href={link.url}
                                            onClick={() => {
                                                handleCategoryAnalytics("featured_category");
                                            }}
                                            showChevron
                                        >
                                            <Text textStyle="body-3" color={"primary"}>
                                                {link.text}
                                            </Text>
                                        </ListItem>
                                    ))}
                                </>
                            ) : (
                                <>
                                    {submenu.trend_menus.map((trendMenu, index) => (
                                        <ListItem
                                            as="button"
                                            key={`${submenu.trend_heading}-${index}`}
                                            onClick={() => {
                                                onCategoryClick?.(trendMenu);
                                                handleCategoryAnalytics("featured_category");
                                            }}
                                            showChevron
                                        >
                                            <Text textStyle="body-3" color={"primary"}>
                                                {trendMenu.display_name}
                                            </Text>
                                        </ListItem>
                                    ))}
                                </>
                            )}
                        </>
                    )}
                </View>
            </div>
            {fullRender && hasTrendMenus && renderSubCategory(submenu, "trend_menus")}
        </>
    );
}
