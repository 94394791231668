import React from "react";
import { DisplayBannerContainer } from "web/react/components/home/stories/display-banner-container";
import { ArchiveDeviceDivider } from "web/react/emo/archive-device-divider";
import { SecondaryStory } from "web/react/emo/secondary-story-rail/secondary-story";
import { View } from "web/react/emo/view";

import { useDomViewport } from "web/react/hooks/use-dom-viewport/use-dom-viewport";
import { SecondaryStoryRailSerializer } from "web/types/serializers";
import * as styles from "./secondary-story-rail.css";

export interface SecondaryStoryRailProps extends SecondaryStoryRailSerializer {
    position: number;
    lazy?: boolean;
}

export function SecondaryStoryRail({
    secondary_stories: storyItems,
    secondary_display_banner: midPageUnit,
    position,
    lazy = false,
}: SecondaryStoryRailProps): React.ReactElement | null {
    const { isMobileViewport, isTabletViewport, isDesktopViewport } = useDomViewport();
    const showMobileMPU = isMobileViewport && midPageUnit;
    const showDesktopMPU = (isTabletViewport || isDesktopViewport) && midPageUnit;

    return (
        <section className={styles.layoutContainer} data-testid="secondary-story-rail">
            {showMobileMPU && (
                <div data-testid="mpu-mobile" className={styles.mpuMobile}>
                    <DisplayBannerContainer
                        {...midPageUnit}
                        position={position}
                        label="mpu"
                        variant="sm"
                    />
                    <ArchiveDeviceDivider />
                </div>
            )}
            <View className={styles.railContainer}>
                {storyItems.map((item, i) => (
                    <SecondaryStory
                        key={`${item.desktop_image_url}-${i}`}
                        {...item}
                        railPosition={i + 1}
                        position={position}
                        lazy={lazy}
                    />
                ))}
                {showDesktopMPU && (
                    <div data-testid="mpu" className={styles.mpuContainer}>
                        <DisplayBannerContainer
                            {...midPageUnit}
                            position={position}
                            label="mpu"
                            variant="sm"
                        />
                    </div>
                )}
            </View>
        </section>
    );
}
