import store from "web/redux/store";
import googleConsentMode from "web/script/analytics/google-consent-mode";

export default {
    init: function () {
        // Set Consent Mode default state
        googleConsentMode.setDefaultConsentMode();
        // Update Consent Mode only if we already have consent cookies
        const state = store.getState().cookieConsentReducer;
        if (state.hasOwnProperty("acceptsAds") && state.hasOwnProperty("acceptsAnalytics"))
            googleConsentMode.updateConsentMode(state);
    },

    send: function () {},
};
