import clsx from "clsx";
import React, { useRef, useState } from "react";
import { useNavigationContext } from "web/react/components/navigation/navigation.context";
import SVGIcon from "web/react/components/svg-icon/svg-icon";
import { Button } from "web/react/emo/button";
import { Flyout } from "web/react/emo/flyout/flyout";
import { ListItem } from "web/react/emo/list-item";
import { Text } from "web/react/emo/text";
import { VStack } from "web/react/emo/v-stack";
import { View } from "web/react/emo/view";
import { gettext } from "web/script/modules/django-i18n";
import { AppPreHeaderBarSerializer } from "web/types/serializers";
import * as styles from "./user-dropdown.css";

export interface UserDropdownProps {
    accountDropdown: AppPreHeaderBarSerializer["account_dropdown"];
    accountUrl: AppPreHeaderBarSerializer["account_url"];
    registerUrl: AppPreHeaderBarSerializer["register_url"];
}

export function UserDropdown({
    accountUrl,
    registerUrl,
    accountDropdown,
}: UserDropdownProps): React.ReactElement {
    const { showMenu, showSearch, handleHeaderAnalytics } = useNavigationContext();

    const [isOpen, setIsOpen] = useState<boolean>(false);

    const flyoutRef = useRef<HTMLDivElement>(null);

    function onItemClick(item): void {
        handleHeaderAnalytics(item.id);
    }

    return (
        <div data-testid="user-dropdown" ref={flyoutRef} className={styles.wrapper}>
            <>
                {accountUrl ? (
                    <Button
                        href={registerUrl as string}
                        onClick={() => {
                            handleHeaderAnalytics("signup_or_login_link");
                        }}
                        title={
                            /* "Sign up or log in" */
                            gettext("header.navigation.sign_in_log_in.cta")
                        }
                    />
                ) : (
                    <div
                        onClick={() => {
                            handleHeaderAnalytics("member_icon");
                            setIsOpen(!isOpen);
                        }}
                        className={styles.iconContainer}
                    >
                        <SVGIcon
                            className={clsx(styles.icon, {
                                [styles.iconGrey]: showMenu || showSearch,
                            })}
                            name="user-icon-emo"
                        />
                        <SVGIcon
                            className={clsx({
                                [styles.iconGrey]: showMenu || showSearch,
                            })}
                            name="chevron-emo"
                        />
                    </div>
                )}
            </>

            {accountDropdown?.nav_items && (
                <div
                    data-testid="flyout"
                    className={clsx(styles.flyOutContent, {
                        [styles.hide]: !isOpen || showMenu || showSearch,
                    })}
                >
                    <Flyout
                        parentRef={flyoutRef}
                        isOpen={isOpen}
                        handleClose={() => setIsOpen(false)}
                    >
                        <View paddingTop="xs" paddingBottom="md">
                            <ul>
                                <VStack spacing="lg" align="center" justify="center">
                                    <div>
                                        {accountDropdown.nav_items.map((item) => (
                                            <ListItem
                                                as="a"
                                                href={item.url}
                                                key={item.url}
                                                onClick={() => onItemClick(item)}
                                                showChevron
                                                data-testid={item.text}
                                            >
                                                <View paddingRight="sm">
                                                    <Text textStyle="body-3">{item.text}</Text>
                                                </View>
                                            </ListItem>
                                        ))}
                                    </div>
                                    <div
                                        data-testid="sign-out-button"
                                        className={styles.buttonContainer}
                                    >
                                        <Button
                                            href={accountDropdown.sign_out.url}
                                            title={accountDropdown.sign_out.text}
                                            variant="secondary"
                                        />
                                    </div>
                                </VStack>
                            </ul>
                        </View>
                    </Flyout>
                </div>
            )}
        </div>
    );
}
