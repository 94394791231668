import { FormikConfig, FormikErrors, FormikHelpers } from "formik";
import { useState } from "react";
import { useSelector } from "react-redux";
import { SocialAuth } from "web/react/components/buttons/social-auth-button";
import { EXPLICIT_EMAIL_CONSENT_COUNTRIES } from "web/react/constants";
import { useDomViewport } from "web/react/hooks/use-dom-viewport/use-dom-viewport";
import { CaptureType } from "web/redux/ducks/customer-capture-overlay";
import { ReduxStoreState } from "web/redux/store";
import analytics from "web/script/analytics/analytics";
import { gettext, gettextNoop } from "web/script/modules/django-i18n";
import environment from "web/script/modules/environment";
import requester from "web/script/modules/requester";
import userProfiler from "web/script/modules/userprofiler";
import storage from "web/script/utils/storage";
import url, { buildLanguageAwareUrlPath } from "web/script/utils/url";
import { validateEmail } from "web/script/utils/validate";
import { generateSearchParams, getCopyDetails, getTypeDetails } from "./soft-signup-utils";

// keys for campaign driven copy, added so included in bundle
export const TITLE_KEYS = [gettextNoop("member_signup.generic_member.title")];
export const SUBMIT_KEYS = [gettextNoop("general.continue")];

export type SoftSignupFormValues = {
    email: string;
    marketing_emails: boolean;
};
interface UseSoftSignupFormProps {
    email?: string;
    onExistingEmail?: (email: string) => void;
}
interface UseSoftSignupForm {
    onSubmit: NonNullable<FormikConfig<SoftSignupFormValues>["onSubmit"]>;
    validate: NonNullable<FormikConfig<SoftSignupFormValues>["validate"]>;
    showSignInLink?: boolean;
    signInLink: string;
    submitText: string;
    type: string;
    title: string;
    subtitle: string;
    initialValues: SoftSignupFormValues;
    captureType: CaptureType;
    requiresOptIn: boolean;
    socialAuthProps: Omit<SocialAuth, "type">;
}

const LOGIN_URL = environment.get("urlPatterns.authLogin");

function useSoftSignupForm({
    email = "",
    onExistingEmail,
}: UseSoftSignupFormProps = {}): UseSoftSignupForm {
    const requiresOptIn = EXPLICIT_EMAIL_CONSENT_COUNTRIES.has(environment.get("country"));
    const {
        captureType,
        designerId,
        next,
        productId,
        searchGender,
        searchTerm,
        designerName,
        blockType,
        titleKey,
        ctaKey,
    } = useSelector((state: ReduxStoreState) => state.customerCaptureOverlay);
    const { isMobileViewport, isTabletViewport, isDesktopViewport } = useDomViewport();

    // fallback for if overlay state does not contain copy details
    const { submitText, title, subtitle } = getCopyDetails(captureType as string, {
        name: designerName || searchTerm,
        blockType: blockType,
        browserMobile: isMobileViewport,
    });

    const type = getTypeDetails(captureType);
    const [signInLink, setSignInLink] = useState(LOGIN_URL);
    const [showSignInLink, setShowSignInLink] = useState(false);

    const initialValues: SoftSignupFormValues = {
        email: email,
        marketing_emails: !requiresOptIn,
    };

    const { searchTermParam, searchGenderParam, nextParam } = generateSearchParams({
        next,
        captureType,
        searchTerm,
        searchGender,
    });

    const socialAuthProps = {
        eventCategory: captureType || "normal",
        isRegistration: true,
        nextUrl: nextParam,
        authType: type,
        productId: productId,
        designerId: designerId,
        searchQuery: searchTermParam,
        searchGender: searchGenderParam,
    };

    async function onSubmit(
        values: SoftSignupFormValues,
        actions: FormikHelpers<SoftSignupFormValues>
    ): Promise<void> {
        actions.setSubmitting(false);
        const seenProducts = userProfiler.getSeenProducts();
        let productIds = [];

        if (seenProducts) {
            productIds = seenProducts.reduce((memo, product) => {
                memo.push(product.productId);
                return memo;
            }, []);
        }
        const { queryParams } = generateQueryParams();
        const body = {
            designer_id: designerId,
            invisible_pids: productIds.join(","),
            privacy_policy: true,
            sign_up_language: environment.get("language"),
            ...values,
        };

        try {
            const path = buildLanguageAwareUrlPath(`/account/register/?${queryParams}`);
            const response = await requester.post(path, body as any);

            if (response && response.success) {
                window.location = response?.data?.next ?? nextParam;

                storage.get("new_user_to_sign_up", null, true) === "active" &&
                    storage.set("new_user_to_sign_up", "complete", true);

                analytics.event(captureType as CaptureType, "submit_signup", "successful_signup");

                return;
            }

            if (response && response.data?.errors?.existing_user) {
                analytics.event(captureType as CaptureType, "sign up", "error_existing_user");
                onExistingUser(values.email);
                return;
            }

            throw new Error(response.data?.errors?.join?.("\n") ?? "Unknown error");
        } catch (e) {
            console.error(e);
            analytics.event(captureType as CaptureType, "error_signup", "server_error");
            return;
        }
    }

    function generateQueryParams(): { queryParams: string } {
        const queryParams = url
            .toQueryString({
                type,
                product_id: `${productId}`,
                designer_id: designerId,
                search_query: searchTermParam,
                search_gender: searchGenderParam,
                email_capture: "true",
                next: nextParam,
            })
            .toString();

        return { queryParams };
    }

    function onExistingUser(email): void {
        if (onExistingEmail && (isTabletViewport || isDesktopViewport)) {
            onExistingEmail(email);
        } else {
            const { queryParams } = generateQueryParams();
            setSignInLink(`${LOGIN_URL}?${queryParams}`);
            setShowSignInLink(true);
        }
    }

    function validate(values: SoftSignupFormValues): FormikErrors<SoftSignupFormValues> {
        const errors: FormikErrors<SoftSignupFormValues> = {};

        if (!values.email) {
            errors.email = gettext("account.register.incorrect_email.label");
            analytics.event(captureType as string, "sign up", "error_empty_email");
        } else if (!validateEmail(values.email)) {
            errors.email = gettext("account.register.incorrect_email.label");
            // we only fire analytics event if the email is invalid
            analytics.event(captureType as string, "sign up", "error_invalid_email");
        }

        return errors;
    }

    return {
        validate,
        onSubmit,
        signInLink,
        showSignInLink,
        submitText: ctaKey ? gettext(ctaKey) : submitText,
        type,
        title: titleKey ? gettext(titleKey) : title,
        subtitle,
        initialValues,
        requiresOptIn,
        socialAuthProps,
        captureType: captureType as CaptureType,
    };
}

export default useSoftSignupForm;
