import clsx from "clsx";
import _escape from "lodash/escape";
import React from "react";
import SVGIcon from "web/react/components/svg-icon/svg-icon";
import { HStack } from "web/react/emo/h-stack";
import { Text } from "web/react/emo/text";
import { gettext } from "web/script/modules/django-i18n";
import { getHtmlProps } from "web/script/modules/html-tag-props";
import * as styles from "./retailer.css";

interface RetailerProps {
    retailerName: string;
    fromSpanStyle: keyof typeof styles.from;
    showLinkIcon?: boolean;
    enableBoldRetailerName?: boolean;
}

export function Retailer({
    retailerName,
    fromSpanStyle,
    showLinkIcon = false,
    enableBoldRetailerName = true,
}: RetailerProps): JSX.Element {
    const fromSpanProps = {
        class: styles.from[fromSpanStyle],
    };

    const retailerNameSpanProps = {
        class: clsx(styles.from[fromSpanStyle], { [styles.retailerName]: enableBoldRetailerName }),
    };

    const retailerLabelSafeHtml = {
        __html: gettext("in_stock_product.from_retailer.in_spans", {
            from_span_props: getHtmlProps(fromSpanProps),
            retailer_name_span_props: getHtmlProps(retailerNameSpanProps),
            retailer_name: _escape(retailerName),
        }),
    };

    return (
        <HStack spacing="xxs">
            {showLinkIcon && <SVGIcon data-testid="icon" name="hyperlink" />}
            <span data-testid="retailer-name" dangerouslySetInnerHTML={retailerLabelSafeHtml} />
        </HStack>
    );
}

export function SponsoredByRetailer({
    retailerName,
    showLinkIcon = false,
}: {
    retailerName: string;
    showLinkIcon?: boolean;
}): JSX.Element {
    return (
        <HStack spacing="xxs">
            {showLinkIcon && <SVGIcon data-testid="icon" name="hyperlink" />}
            <Text as="span" textStyle="body-3" lineLimit={2}>
                {
                    // Sponsored by {retailerName}
                    gettext("in_stock_product.sponsored_by_retailer", {
                        retailer_name: retailerName,
                    })
                }
            </Text>
        </HStack>
    );
}
